/* stylelint-disable no-descending-specificity */
@media print {
  * {
    font-size: 10pt !important;
    background-color: white
  }

  input[type="radio"] {
    margin-right: 1em
  }
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
code,
del,
dfn,
em,
img,
q,
dl,
dt,
dd,
ol,
ul,
li,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit
}

em {
  font-style: oblique
}

legend,
fieldset {
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  vertical-align: baseline
}

fieldset {
  padding: 1em
}

body {
  margin: auto;
  font-size: 12px;
  line-height: 1.5;
  color: #363636;
  background: white !important
}

nav.custom-top-bar,
div.page-totals {
  font-size: 12px;
}

q::before,
q::after {
  content: ""
}

q {
  quotes: "" ""
}

a img {
  border: none
}

h1 {
  padding: 0;
  margin: 0 0 10px;
  font-size: 24px;
  font-weight: bold
}

h2 {
  padding: 0;
  margin: 0 0 10px;
  font-size: 18px;
  font-weight: bold
}

h3 {
  padding: 0;
  margin: 0 0 10px;
  font-size: 16px;
  font-weight: bold
}

h4 {
  padding: 0;
  margin: 0 0 10px;
  font-size: 14px;
  font-weight: bold
}

a {
  color: #1f92ff;
  text-decoration: none;
  text-shadow: 0 0 0 transparent
}

a:hover {
  color: #2f4e85;
  text-decoration: none;
  text-shadow: 0 0 0 transparent
}

a:active {
  color: #000;
  text-decoration: none;
  text-shadow: 0 0 0 transparent
}

.clear {
  clear: both
}

.show {
  display: block
}

.hide {
  display: none
}

table {
  margin: auto;
  text-align: left
}

img {
  border: 0
}

ul.style,
ol.style {
  padding-bottom: 15px;
  padding-left: 30px
}

ul.style li,
ol.style li {
  padding-left: 5px;
  margin-bottom: 5px
}

label {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 14px;
  font-weight: 500;
  color: #212529
}

input.search {
  width: 180px; /* important */
  background: transparent;
  border: 0;
}

input[type="number"]:hover,
input[type="text"]:hover,
input[type="password"]:hover,
textarea:hover {
  background-color: #fff5d3;
  border: 1px solid #999
}

input[type="number"]:focus,
input[type="text"]:focus,
input[type="password"]:focus,
textarea:focus {
  background-color: #fff5d3
}

input[type="number"].blur,
input[type="text"].blur,
input[type="password"].blur,
textarea.blur {
  font-style: italic;
  font-weight: normal;
  color: #999
}

input.search:hover,
input.search:focus {
  background: transparent;
  border: 0
}

#content {
  min-width: 700px;
  margin-left: 10px
}

#content .inner {
  float: left;
  width: 99.5%
}

table.data {
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc
}

table.none tr td,
table.none tr th {
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff
}

table.data tr td,
table.data tr th {
  padding: 7px;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc
}

table.data tr th {
  font-weight: bold;
  background: #eee !important
}

table.data tr td {
  color: #666
}

table.data thead a.change-order {
  color: inherit
}

table.data#specs-table tr th {
  position: sticky;
  left: 0;
  width: 5rem;
  border-right: 1px solid #ccc;
}

table.data#specs-table tr td {
  width: max-content;
  min-width: 10rem;
  padding: 6px;
  border-bottom: 0;
}

table.data#specs-table tr td select {
  margin: 0;
}

table.data div.module-type-selector > div {
  width: 100%;
  height: 10rem;
  padding: 0.5rem 0.25rem;
  overflow: auto;
}

.alert.warning {
  padding: 1em;
  margin: 10px 0;
  font-weight: bold;
  color: #333;
  text-shadow: 0 0 0 transparent;
  cursor: pointer;
  background: #fcf2a5;
  border: 1px solid #edbf06
}

.alert.info {
  padding: 1em;
  margin: auto;
  font-weight: bold;
  color: #333;
  text-shadow: none;
  cursor: pointer;
  background: #e6f5fd;
  border: 1px solid #b2c8ff
}

.alert.success {
  padding: 1em;
  margin: 10px 0;
  font-weight: bold;
  color: #fff;
  text-shadow: none;
  cursor: pointer;
  background: #7a7;
  border: 1px solid #49816e
}

.alert.error {
  padding: 1em;
  margin: 10px 0;
  font-weight: bold;
  color: black;
  text-shadow: none;
  background-color: #ffbcbc;
  border: 2px solid #ff8e8e
}

.alert.warning p,
.alert.info p,
.alert.success p,
.alert.error p {
  margin: 15px
}

div.error {
  width: auto;
  color: #f00
}

.mfp-ajax-holder .mfp-content {
  text-align: center
}

.mfp-wrap .mfp-content {
  text-align: center
}

.row {
  max-width: 78rem
}

label.ui-corner-all {
  color: #fff
}

form .form-widget .row {
  margin-bottom: 0.5rem
}

select.multiple {
  height: 6rem
}

meta.foundation-mq-topbar {
  width: 40.063em
}

.top-bar-section ul li > a.button.secondary {
  background-color: #e7e7e7;
  border-color: #b9b9b9
}

.top-bar-section ul li > a.button.secondary:hover,
.top-bar-section ul li > a.button.secondary:focus {
  background-color: #b9b9b9
}

.top-bar-section ul li > button.secondary {
  background-color: #e7e7e7;
  border-color: #b9b9b9
}

.top-bar-section ul li > button.secondary:hover,
.top-bar-section ul li > button.secondary:focus {
  background-color: #b9b9b9
}

.no-js .top-bar-section ul li:active > a {
  background: #2DB1FF
}

.panel.callout {
  background: #ecfaff;
  border-color: #b6edff
}

.panel.callout a:not(.button):hover,
.panel.callout a:not(.button):focus {
  color: #0078a0
}

.th:hover,
.th:focus {
  box-shadow: 0 0 6px 1px rgb(0 140 186 / 50%)
}

ul.pagination a {
  border: 1px solid #ccc
}

a:hover,
a:focus {
  color: #8bbceb
}

code {
  border-color: #dfdfdf
}

.has-tip:hover,
.has-tip:focus {
  color: #2DB1FF;
  border-bottom: dotted 1px #003f54
}

.tooltip {
  padding: 0.45rem;
  font-size: 0.725rem
}

.tooltip.opened {
  color: #2DB1FF !important;
  border-bottom: dotted 1px #003f54 !important
}

.label.secondary {
  background-color: #e7e7e7
}

/* THIS SHOULD BE ONLY AT OUR CUSTOM TABLES */
table thead tr th,
table thead tr td {
  font-size: 0.725rem
}

table tfoot tr th,
table tfoot tr td {
  font-size: 0.725rem
}

table tr th,
table tr td {
  font-size: 0.725rem
}

.has-tip {
  border-bottom: none
}

.has-tip:hover {
  border-bottom: none
}

body.open-nav div.main {
  margin-left: 240px
}

body.open-nav .side-nav {
  left: 0;
  overflow: auto;
}

body.open-nav .side-nav div.hirescore-logo-container {
  position: sticky;
  top: 0;
  display: block;
  padding-bottom: .25rem;
  background: #222b37;
  box-shadow: 0 1px 2px #222b37;
}

header,
section,
footer,
aside,
nav,
article,
figure {
  display: block
}

div.main .custom-top-bar {
  position: fixed;
  top: 0;
  z-index: 149;
  width: 100%;
  height: 50px;
  background-color: white;
  border-bottom: 1px solid #ccc
}

.custom-top-bar .menu-open {
  display: inline-block;
  width: 5px;
  padding: 11px 5px;
  color: #5a5a5a;
  text-align: center;
  cursor: pointer;
  border-right: none;
  border-right: #ccc solid 2px;
  opacity: 0
}

.custom-top-bar .menu-open.open {
  width: 40px;
  opacity: 1
}

a.action-icon {
  margin-right: 2px;
}

a.action-icon i,
i.action-icon {
  color: #5d676f;
}

a.action-icon.nonclickable i:hover,
i.action-icon.nonclickable:hover {
  color: #5d676f;
  cursor: auto
}

a.action-icon:hover,
a.action-icon i:hover,
i.action-icon:hover,
a.action-icon:hover i {
  color: #989ea4;
  cursor: pointer
}

.subtle-action {
  color: #bdbdbd
}

.subtle-action:hover {
  color: #717171
}

.crumbs {
  position: absolute;
  top: 10px;
  display: inline-block;
  height: 28px;
  margin-left: 5px
}

.crumbs-content {
  display: inline-block;
  padding-top: 5px
}

.crumbs span,
.crumbs a {
  font-weight: bold;
  color: #5a5a5a;
  text-transform: uppercase
}

.crumbs span:not(:first-child)::before {
  content: ' > '
}

@media only screen and (width <= 40em) {
  .crumbs span:not(:last-child) {
    display: none
  }

  .crumbs span:last-child::before {
    content: ''
  }
}

.profile {
  position: fixed;
  top: 10px;
  right: 0;
  display: inline-block;
  height: 39px;
  padding-right: 1em;
  padding-left: 5px;
  background-color: white;
}

.profile > a {
  font-weight: bold;
  color: #5a5a5a
}

div.main .content {
  padding-top: 80px
}

div.main {
  position: relative;
  transition: margin-left ease 0.5s
}

#footer {
  position: relative;
  padding: 20px;
  margin-top: 3rem;
  font-size: 14px;
  color: #999;
  border-top: 1px solid #ccc;
}

#footer ul {
  padding: 20px 0 0;
  list-style-type: none;
}

#footer ul li {
  margin-bottom: 10px;
}

.side-nav {
  position: fixed;
  top: 0;
  left: -240px;
  z-index: 150;
  width: 240px;
  height: 100%;
  padding: 0 !important;
  background: #222b37;
  transition: left ease 0.5s
}

.side-nav li.menu-item {
  margin-bottom: 0
}

.side-nav li.menu-item:hover {
  background: rgb(255 255 255 / 30%)
}

.side-nav li.menu-item.has-link:hover > a.menu-action .title {
  text-decoration: underline
}

/* Closed sub menu li is hovered over, regardless of whether or not it has a link */
.side-nav li.menu-item.has-sub-menu:hover > a {
  background: rgb(255 255 255 / 30%)
}

/* sub-expand button is hovered over at any time */
.side-nav li.menu-item.has-sub-menu > a.sub-expand:hover {
  background: rgb(255 255 255 / 40%) !important
}

.side-nav li.menu-item.has-sub-menu.open {
  font-weight: bolder
}

.side-nav li.menu-item.active > a {
  background: #2db1ff !important
}

li a.menu-action {
  width: 100%
}

li.has-sub-menu a.menu-action {
  width: 80%
}

div#nav-menu > ul.menu.open > li.menu-item > a {
  font-size: 16px
}

ul.menu.sub-menu:not(.open),
li.menu-item.has-sub-menu:not(.open) {
  background-color: #222b37;
  transition: background-color 150ms
}

ul.menu.sub-menu.open li,
li.menu-item.has-sub-menu.open {
  background-color: #455f85;
  transition: background-color 150ms
}

ul.menu.sub-menu.open .menu.sub-menu.open li,
ul.menu.sub-menu.open li.menu-item.has-sub-menu.open {
  text-shadow: 0 0 3px rgb(29 38 48 / 20%);
  background-color: #81a9d4;
  transition: background-color 150ms
}

ul.menu.sub-menu.open .menu.sub-menu.open .menu.sub-menu.open li,
ul.menu.sub-menu.open .menu.sub-menu.open li.menu-item.has-sub-menu.open {
  text-shadow: 0 0 3px rgb(29 38 48 / 20%);
  background-color: #61b1ef;
  transition: background-color 150ms
}

li.menu-item.has-sub-menu.open:not(.has-link) > a.menu-action {
  pointer-events: none;
  cursor: default
}

/* Incrementally increase by 5% for each submenu */
.menu.sub-menu li a.menu-action {
  padding-left: 9%
}

.menu.sub-menu .menu.sub-menu li a.menu-action {
  padding-left: 14%
}

.menu.sub-menu .menu.sub-menu .menu.sub-menu li a.menu-action {
  padding-left: 19%
}

.menu.sub-menu .menu.sub-menu .menu.sub-menu .menu.sub-menu li a.menu-action {
  padding-left: 24%
}

.side-nav li a.sub-expand {
  float: right;
  width: 20%;
  color: white;
  text-align: center
}

.side-nav li.menu-item.active a.sub-expand:hover {
  color: white;
  background: #6aa8e4
}

.side-nav li a:not(.button) {
  display: inline-block
}

.side-nav li a:not(.button):hover {
  color: white;
  background: rgb(255 255 255 / 30%)
}

.side-nav .menu-close:hover {
  color: white
}

.side-nav.open {
  left: 240px
}

.side-nav .hirescore-logo-container {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem
}

.side-nav .hirescore-logo-container > a.close-nav {
  width: 25%;
  padding: 0.5rem;
  font-size: 18px;
  color: white;
  vertical-align: -22%
}

.side-nav .hirescore-logo-container > a.close-nav:hover {
  color: #2DB1FF
}

.side-nav .hirescore-logo-container > a.hs-logo {
  width: 75%;
  padding: 0.4rem
}

.side-nav .hirescore-logo-container > a.hs-logo > img {
  width: inherit;
  padding: inherit
}

.side-nav ul {
  padding: 0;
  margin: 0;
  list-style-type: none
}

.side-nav ul .sub-menu {
  margin: 0;
  list-style-type: none
}

.menu li > a.menu-action {
  color: white !important
}

.form-error ul {
  list-style: none
}

small.error ul {
  list-style: none
}

small.error ul li {
  color: white
}

.shadow-box {
  box-shadow: 0 4px 8px 2px rgb(0 0 0 / 20%);
  transition: 0.3s
}

.shadow-box:hover {
  box-shadow: 0 8px 16px 2px rgb(0 0 0 / 20%)
}

.disabled {
  pointer-events: none;
  opacity: 0.5
}

.form-error ul li {
  color: white
}

.mce-container button {
  background-color: transparent
}

.has-tip.has-no-border {
  border-bottom: none
}

table tr td.bgshade {
  background: repeating-linear-gradient(-45deg, #eee 7px, #fff 10px, #f5f5f5 12px);
}

table,
tr,
td,
th,
tbody,
thead,
tfoot {
  page-break-inside: avoid !important
}

.text-green {
  color: #0ca678
}

.text-red {
  color: #cf2a0e
}

.clear-bottom {
  margin-bottom: 30px
}

.dropdown-no-focus {
  outline: none
}

button.mfp-close:hover {
  background-color: inherit
}

.post-action {
  display: inline
}

.post-action button {
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  font-size: inherit;
  background-color: inherit
}

button.minuscule,
.button.minuscule {
  padding: 0.3125rem 0.625rem 0.3438rem;
  font-size: 0.6875rem
}

.action-set-hidden {
  width: 100%;
  padding: 0;
  text-align: center;
  opacity: 0;
}

.action-set-hidden div.dropdown-wrapper ul {
  width: auto;
  min-width: 150px;
  outline: none;
}

.action-set-hidden div.dropdown-wrapper ul li * {
  font-weight: 700
}

.action-set-hidden div.dropdown-wrapper ul li:hover * {
  color: black;
  background-color: #eee;
}

div.action-set-hidden > a.action-icon {
  display: inline-block;
  width: 100%;
  height: 100%;
}

tr:hover .action-set-hidden {
  opacity: 1;
}

::placeholder {
  color: #adb5bd;
  opacity: 1
}

.assessment-top-bar {
  width: 100%;
  color: white
}

.assessment-top-bar span.assessment-title-wrapper,
.assessment-top-bar span.assessment-timer-wrapper {
  margin-left: 15px;
  font-size: 1.15rem;
  font-weight: bold
}

.assessment-top-bar span.assessment-timer-wrapper {
  color: #7ab5ef
}

#next-unanswered-question-button {
  top: -1px;
  display: none;
  margin-left: 15px
}

div.assessment-page-wrapper div.assessment-question-wrapper div.assessment-question-content {
  font-size: 0.95rem
}

div.assessment-page-wrapper div.assessment-question-wrapper {
  padding: 10px;
  margin-top: 10px;
  page-break-inside: avoid;
  border-radius: 5px
}

div.assessment-page-wrapper div.assessment-question-wrapper div.assessment-answer-wrapper {
  margin-top: 5px;
  margin-left: 20px;
  font-size: 0.9rem;
  font-weight: lighter
}

div.assessment-page-wrapper div.assessment-question-wrapper div.assessment-answer-wrapper label {
  margin-bottom: 1rem;
  font-weight: normal
}

div.assessment-page-wrapper div.assessment-question-wrapper div.assessment-answer-wrapper label input[type="radio"] {
  margin-bottom: 0
}

div.assessment-page-wrapper div.assessment-submit-wrapper {
  width: 100%;
  margin-top: 25px;
  text-align: center
}

div.assessment-page-wrapper div.assessment-question-wrapper div.assessment-content-text-block {
  max-height: 600px;
  padding: 20px;
  overflow: auto;
  background-color: white;
  border: 2px lightgray solid
}

div.assessment-page-wrapper div.assessment-question-wrapper div.assessment-answer-wrapper label.vertical input {
  margin-right: 3px
}

div.assessment-page-wrapper div.assessment-question-wrapper div.assessment-answer-wrapper div.assessment-answer-flex-container {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap
}

div.assessment-page-wrapper div.assessment-question-wrapper div.assessment-answer-wrapper label.horizontal {
  -webkit-box-flex: 1;
  flex: 1 1 0;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center
}

div.assessment-page-wrapper div.assessment-question-wrapper div.assessment-comment-wrapper {
  margin-top: 10px
}

div.assessment-page-wrapper div.assessment-question-wrapper div.assessment-question-content div.assessment-question-content-numbered {
  display: -webkit-box;
  display: flex
}

div.assessment-page-wrapper div.assessment-question-wrapper div.assessment-question-number {
  margin-right: 8px;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.2rem;
  color: gray
}

div.assessment-page-wrapper div.assessment-question-wrapper div.assessment-question-content-text {
  display: block;
  width: 95%
}

div.assessment-page-wrapper div.assessment-question-wrapper div.assessment-competency-list ul {
  margin-bottom: 10px;
  margin-left: 50px
}

#unanswered-question-modal input[type="button"] {
  margin-right: 10px
}

#assessor-widget {
  min-height: 225px
}

#process-details-wrapper #add-location-btn {
  padding-left: 0.125rem
}

#process-details-wrapper .wrapper {
  position: relative;
  max-width: 100%;
  max-height: 52rem;
  overflow: auto;
  border: 1px solid lightgrey
}

#process-details-wrapper thead th {
  position: sticky;
  top: 0;
  width: 150px;
  min-width: 150px;
  text-align: center !important
}

#process-details-wrapper tbody th {
  position: sticky;
  left: 0;
  white-space: nowrap
}

#process-details-wrapper tbody > tr:nth-of-type(even) > th {
  background: aliceblue;
  border-right: 1px solid lightgrey
}

#process-details-wrapper tbody > tr:nth-of-type(odd) > th {
  background: #fff;
  border-right: 1px solid lightgrey
}

#process-details-wrapper tbody > tr > th {
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey
}

#process-details-wrapper thead th:first-child {
  left: 0;
  z-index: 1;
  text-align: left;
  background: aliceblue;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey
}

#process-details-wrapper table#competency-table {
  position: relative;
  float: left;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  border: none
}

#process-details-wrapper td.cycle-value {
  text-align: center !important
}

#process-details-wrapper select[name="select-location"] {
  width: 75%;
  margin-right: 1rem
}

#process-details-wrapper tr.competency-row[data-parentid='0'] {
  font-weight: bold
}

#process-details-wrapper #competency-table .location-head {
  background: #f9f9f9 !important;
  border-right: none;
  border-bottom: 1px solid lightgrey
}

#process-details-wrapper li.accordion-navigation {
  list-style: none
}

#process-details-wrapper li.accordion-navigation > a {
  transition: background-color 500ms ease-out
}

#process-details-wrapper li.accordion-navigation:first-child > a {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px
}

#process-details-wrapper li.accordion-navigation:last-child > a {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px
}

#process-details-wrapper li.accordion-navigation:last-child > div.content.active {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px
}

#process-details-wrapper li.accordion-navigation:not(.active) > a {
  transition: background-color 150ms ease-out, color 150ms ease-out, border-color 1000ms ease-out
}

#process-details-wrapper li.accordion-navigation.active > a {
  color: #fff !important;
  background: linear-gradient(to bottom, #2DB1FF 0%, #207fc0 100%) !important;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  transition: background-color 150ms ease-out, color 150ms ease-out, border-color 1000ms ease-out
}

#process-details-wrapper li.accordion-navigation > a:hover {
  transition: background-color 500ms ease-out
}

#process-details-wrapper ul.accordion {
  margin-bottom: 5rem;
  border-radius: 20px;
  box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 30%)
}

#process-details-wrapper .a-content.active {
  min-height: 1rem;
  background-color: #efefef !important
}

#process-details-wrapper .inner-content {
  padding: 1rem;
  background: #fff !important;
  border-radius: 20px;
  box-shadow: inset 1px 1px 3px 0 rgb(0 0 0 / 30%)
}

#skillbuilder-cycle-competency-view .category td {
  background-color: #e9e9e9
}

#skillbuilder-cycle-competency-view table {
  border-spacing: 0
}

#skillbuilder-cycle-competency-view .cycle-header-list-view-wrapper {
  margin-bottom: 10px
}

.help-wrapper em {
  font-style: italic
}

.help-wrapper ol,
.help-wrapper ul {
  padding-left: 2em;
  margin: 0.5em 0
}

.help-wrapper p {
  margin: 0.5em 0
}

table.dimensions-table {
  width: auto;
  border-collapse: collapse;
  border-radius: 20px
}

table.dimensions-table tbody td {
  width: auto;
  padding: 0.25rem 0.5rem
}

table.dimensions-table thead td {
  width: auto !important
}

.default-pagination {
  display: inline;
  float: right;
  margin-top: 5px;
  margin-bottom: 5px
}

#cycle-create-wrapper div.select-modules {
  width: 650px;
  margin-bottom: 10px
}

#cycle-create-wrapper div.select-modules div.ms-selectable,
div.select-modules div.ms-selection {
  width: 300px
}

#cycle-create-wrapper div.select-modules div.ms-selectable ul.ms-list,
div.select-modules div.ms-selection ul.ms-list {
  height: 450px
}

#cycle-create-wrapper li.pap-surveys {
  width: 55em;
  padding: 5px;
  margin-bottom: 2em;
  border: 1px solid #bdbdbd
}

#cycle-create-wrapper a.pap-survey-delete {
  margin-left: 1em;
  cursor: pointer
}

.icims-job-id {
  display: none
}

.adp-requisition {
  display: none
}

#exports .button-container {
  display: inline-block;
  width: 200px;
  padding: 1em;
  margin: 1em;
  text-align: center;
  cursor: pointer;
  background-color: #fafafa;
  border-radius: 10px;
  box-shadow: 2px 2px 4px #ccc;
  opacity: 0;
  transition: 0.4s
}

#exports .button-container:hover {
  background-color: #d9e6fc;
  box-shadow: none;
  transition: 0.2s
}

#exports .button-icon i {
  font-size: 6em;
  color: #a591ff;
  transition: 0.4s
}

#exports .button-container:hover .button-icon i {
  color: #6c4bfc;
  transition: 0.2s
}

#exports .button-title {
  width: 100%;
  padding: 0 0 5px;
  margin: auto auto 1em;
  border-bottom: 1px solid #ccc
}

table.skillbuilder-reports-candidates-table {
  min-width: 60%;
  text-shadow: none !important;
  border-collapse: collapse
}

table.skillbuilder-reports-candidates-table th {
  font-weight: bold;
  color: white !important;
  text-align: center !important;
  background-color: #2DB1FF
}

table.skillbuilder-reports-candidates-table .score {
  text-align: center
}

#delete-applicant-wrapper .panel {
  margin-top: 10px;
  margin-bottom: 0;
  transition: all 0.3s
}

#delete-applicant-wrapper .panel:hover {
  cursor: pointer;
  box-shadow: 0 0 10px 1px #ccc;
  transition: all 0.3s
}

#delete-applicant-wrapper .panel label {
  font-size: 1.25em
}

#delete-applicant-wrapper .panel input {
  margin-right: 0.5em
}

#applicant-import-wrapper {
  font-size: 14pt
}

#applicant-import-wrapper img {
  margin-bottom: 0.5rem
}

#applicant-import-wrapper h3 {
  margin-top: 0.5rem;
  font-size: 14pt
}

#applicant-import-wrapper h4 {
  margin-top: 0.5rem;
  font-size: 14pt
}

#applicant-import-wrapper ul {
  margin-top: 0.1rem;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  font-size: 12pt
}

div.file {
  position: relative;
  width: 400px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc
}

a.remove {
  position: absolute;
  top: -5px;
  right: -5px
}

#title,
#felony,
#referrer,
#workedfor,
#bankexperience,
#highexpertise,
#fired-flag-wrapper {
  margin-bottom: 20px
}

.tr-selected {
  background-color: #2DB1FF !important;
  opacity: 0.8
}

#template-datatable tbody tr:hover {
  cursor: pointer;
  background-color: lightgray
}

.select2-search__field { /* stylelint-disable-line selector-class-pattern */
  width: 100% !important
}

.select2-container .select2-selection {
  max-height: 5rem;
  overflow-y: auto
}

#question-datatable tr {
  cursor: pointer
}

#top-bar-search {
  display: inline-block;
  margin-right: 10px;
  vertical-align: text-bottom;
}

#top-bar-search input {
  height: 28px;
  margin-bottom: 0
}

#sb-contact-form .row {
  margin-top: 1em
}

#sb-contact-form .shadow-box {
  padding: 1.5em;
  margin-left: 1em;
  background-color: #f5f7fa;
  border-radius: 10px;
  box-shadow: 2px 2px 4px #ccc
}

#sb-contact-form .candidate-box {
  padding-top: 0.8em;
  margin-top: 0.8em;
  border-top: 1px solid #ccc
}

#sb-contact-form #preview {
  display: none;
  margin-top: 2em
}

#sb-contact-form .legend {
  display: inline-block;
  padding: 1em;
  margin: 1em;
  background-color: #f5f7fa;
  border-radius: 3px;
  box-shadow: 2px 2px 4px #ccc
}

#sb-contact-form .legend h4 {
  padding-bottom: 0.5em;
  font-size: 0.9em;
  text-align: center;
  border-bottom: 1px solid #ccd1d9
}

.assessment-media-wrapper img {
  font-size: .95rem;
  color: red;
}

.filter-bar {
  z-index: 10;
  width: 100%;
  padding: 1em;
  margin-top: -10px;
  margin-bottom: 10px;
  overflow-x: auto;
  color: #333;
  white-space: nowrap;
  border-bottom: 1px solid #ccc;
}

.filter-bar ul {
  padding: .2em .4em;
  list-style: none;
}

.filter-bar li:not(.button) {
  display: inline-block;
  padding: .3em .7em;
  margin: 0 .4em;
  border: 1px solid #666;
  border-radius: 20px;
}

.filter-bar li span {
  margin: .2em;
}

.filter-bar .active-title {
  font-weight: bold;
}

.filter-bar .active-remove-filter {
  color: orangered;
  cursor: pointer;
}

.filter-bar .active-remove-filter:hover {
  color: red;
}

.filter-bar #toggle-filters {
  color: white;
  cursor: pointer;
  background-color: #2DB1FF;
}

.filter-bar #toggle-filters:hover {
  color: white;
  background-color: #207fc0;
}

.filter-bar #all-filters {
  display: none;
  padding-left: 1em;
}

.filter-bar .filter-box {
  display: inline-block;
  height: 80px;
  padding-top: 1em;
  margin: 1em;
  overflow: hidden;
  white-space: nowrap;
}

.filter-bar .filter-box-lg {
  width: 200px;
}

.filter-bar .filter-box-md {
  width: 150px;
}

.filter-bar .filter-box-sm {
  width: 70px;
}

@media only screen and (width <= 40em) {
  .filter-bar .filter-box {
    display: block;
    width: 90%;
  }
}

.filter-bar .filter-box select, .filter-bar .filter-box input {
  border-radius: 20px;
}

.filter-bar .filter-title {
  display: block;
  margin-left: 5px;
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}

#invite-applicant-wrapper .highlight-row {
  background-color: #adcae6 !important;
}

#good-feedback:hover {
  color: darkgreen;
  cursor: pointer;
}

#good-feedback.chosen {
  color: darkgreen;
  cursor: default !important;
}

#bad-feedback:hover {
  color: darkred;
  cursor: pointer;
}

#bad-feedback.chosen {
  color: darkred;
  cursor: default !important;
}

table#hired-applicants-table input.invalid {
  background: lightcoral;
}

div.content.assessment-platform-content {
  min-height: 80vh;
}

span.select2-container span.select2-selection--single { /* stylelint-disable-line selector-class-pattern */
  height: fit-content !important;
}

span.redacted {
  filter: blur(6px);
}

label.required::after {
  color: #fa5252;
  content: " *";
}

form[name="login"] label.required::after,
form[name="recover_password"] label.required::after,
form[name="ap_login"] label.required::after,
form[name="single_sign_on"] label.required::after {
  content: "";
}

.float-right {
  float: right;
}

.login-link {
  font-size: 16px;
  font-weight: bold;
  color: #2DB1FF;
}

.login-link:hover {
  color: #008EE2;
}

.legal-documents {
  font-size: 14px
}

.legal-documents h2 {
  margin-top: 25px
}

.legal-documents h3 {
  margin-top: 25px
}

.legal-documents p {
  margin-top: 13px
}

.legal-documents ul {
  margin-top: 20px;
  margin-left: 30px
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

#login-container {
  padding: 40px;
  background-color: white;
  animation: fadein 0.75s;
}

#login-container label {
  font-size: 16px;
  font-weight: 700;
  color: gray;
  cursor: default;
}

.content select {
  margin: 0 0 1rem;
  font-family: sans-serif;
  font-size: .875rem;
}

.cell-background-green {
    background-color: #9f9;
}

.cell-background-yellow {
    background-color: #ff9;
}

.cell-background-red {
    background-color: #f99;
}

@media print {
    .cell-background-green {
        background-color: #9f9 !important;
        print-color-adjust: exact;
    }

    .cell-background-yellow {
        background-color: #ff9 !important;
        print-color-adjust: exact;
    }

    .cell-background-red {
        background-color: #f99 !important;
        print-color-adjust: exact;
    }
}

body.body-login {
  background-color: #f3f3f4 !important
}

#specs-table input[type='text'] {
  margin-bottom: 0
}

.category-choice .radio label::after {
  content: "";
}

/* stylelint-enable no-descending-specificity */
