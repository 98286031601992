.data-view {
  position: relative;
}

.data-view table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
}

.data-view table td,
.data-view table th {
  border: 1px solid #ccc;
}

.data-view table tr td,
.data-view table tr th {
  padding: 7px;
}

.data-view table tr td {
  color: #666;
}

.data-view table tr:first-child td {
  border-top: 0;
}

.data-view table tr td:first-child {
  border-left: 0;
}

.data-view table tr:last-child td {
  border-bottom: 0;
}

.data-view table tr td:last-child {
  border-right: 0;
}

.data-view table tbody tr.clickable-table-row:hover {
  background-color: #e6e6e6 !important;
}

.data-view table thead tr th {
  font-weight: bold;
  color: #222;
  text-align: center;
  background-color: #eee;
}

.data-view table thead tr th[data-collapser-parent] {
  background-color: #e8e8e8;
}

.data-view table thead tr th.sortable {
  cursor: pointer;
}

.data-view table thead tr th a.sortable {
  color: #1f92ff;
}

.data-view table thead div.module-header-dropdown {
  display: none;
}

.data-view table thead div.module-header-dropdown.open {
  display: block;
  width: auto;
  height: 6rem;
  padding: 1rem;
  background-color: white;
  border: 1px solid darkgray;
}

.data-view table tbody tr td.hidden,
.data-view table thead tr th.hidden {
  display: none !important;
}

.data-view table thead tr th a.collapser {
  float: right;
  margin-left: 2px;
  font-size: 1.5em;
  color: #5d676f;
}

.data-view table thead tr th a.collapser:hover {
  color: black;
}

input.row-multi-selector {
  display: none;
}

.highlight-row {
  background-color: #adcae6;
}

.data-view .page-data-wrapper {
  float: right;
  margin-bottom: 15px;
}

.data-view .page-data-wrapper.bot {
  margin-top: 10px;
}

.data-view .page-data-wrapper .page-limit {
  margin-bottom: 0;
  vertical-align: bottom;
}

.data-view .pager-wrapper {
  display: inline-block;
}

.data-view .pager-wrapper .pagination {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 3px;
}

.data-view .limit-wrapper {
  display: inline-block;
}

.data-view .page-totals {
  width: 50%;
  padding: 19px 10px 10px;
  margin: auto auto auto 10%;
  text-align: center;
}

.data-view .search-wrapper,
.data-view .reset-sorting-wrapper {
  position: relative;
  float: left;
}

.data-view .reset-sorting-wrapper {
  top: 10px;
  left: 10px;
  vertical-align: top;
}

.data-view .search-wrapper input,
.data-view .search-wrapper select {
  margin-bottom: 15px;
}

.data-view .search-wrapper .search-button {
  position: absolute;
  top: 10px;
  left: 9px;
  vertical-align: top;
}

.data-view .search-wrapper .search-select {
  display: inline-block;
  width: auto;
  padding-right: 2em;
  vertical-align: top;
}

.data-view .search-wrapper .search {
  padding-left: 30px;
  border: 1px solid #ccc;
}

.data-view .search-wrapper .search-input {
  position: relative;
  display: inline-block;
}

.preloader {
  position: absolute;
  z-index: 80;
  width: 100%;
  height: 35px;
}

#loading-animation {
  width: 100%;
  height: 100%;
}

.sub-animation {
  position: relative;
  top: 0;
  width: 100%;
  height: 4px;
  overflow: hidden;
  background-color: #ddd;
}

.sub-animation::before {
  position: absolute;
  left: -200px;
  display: block;
  width: 200px;
  height: 4px;
  content: "";
  background-color: #008EE2;
  animation: loading 2s linear infinite;
}

@keyframes loading {
  0% {
    left: -200px;
    width: 30%;
  }

  50% {
    width: 30%;
  }

  70% {
    width: 70%;
  }

  80% {
    left: 50%;
  }

  95% {
    left: 120%;
  }

  100% {
    left: 100%;
  }
}

div.dropdown {
  text-align: center;
}

ul.f-dropdown {
  text-align: left;
}

td[data-column*='Score'],
td[data-column*='score'],
td[data-column='a.tagId'],
td[data-column='a.flags'] {
  text-align: center;
}

.data-view td[data-column='row-selector'] input:checked,
.data-view tr:hover td[data-column='row-selector'] input {
  display: block;
}

div#bulk-actions {
  position: fixed;
  left: 250px;
  z-index: 1;
  display: inline-block;
  padding: 0.5rem;
  font-weight: bold;
  background: #d6dce4;
  border: 1px solid #CCC;
  border-radius: 10px;
  opacity: 1;
  transition: opacity 150ms;
}

div#bulk-actions ul {
  margin: 0;
  text-align: center;
  list-style: none;
}

div#bulk-actions.disabled {
  opacity: 0;
  transition: opacity 150ms;
}

.data-view .f-dropdown.drop-left::before,
.f-dropdown.drop-left::after { /* remove arrow from dropdown left */
  display: none;
}
