/* stylelint-disable no-descending-specificity */
meta.foundation-version {
  font-family: "/5.5.3/", sans-serif;
}

meta.foundation-mq-small {
  width: 0;
  font-family: "/only screen/", sans-serif;
}

meta.foundation-mq-small-only {
  width: 0;
  font-family: "/only screen and (max-width: 40em)/", sans-serif;
}

meta.foundation-mq-medium {
  width: 40.0625em;
  font-family: "/only screen and (min-width:40.0625em)/", sans-serif;
}

meta.foundation-mq-medium-only {
  width: 40.0625em;
  font-family: "/only screen and (min-width:40.0625em) and (max-width:64em)/", sans-serif;
}

meta.foundation-mq-large {
  width: 64.0625em;
  font-family: "/only screen and (min-width:64.0625em)/", sans-serif;
}

meta.foundation-mq-large-only {
  width: 64.0625em;
  font-family: "/only screen and (min-width:64.0625em) and (max-width:90em)/", sans-serif;
}

meta.foundation-mq-xlarge {
  width: 90.0625em;
  font-family: "/only screen and (min-width:90.0625em)/", sans-serif;
}

meta.foundation-mq-xlarge-only {
  width: 90.0625em;
  font-family: "/only screen and (min-width:90.0625em) and (max-width:120em)/", sans-serif;
}

meta.foundation-mq-xxlarge {
  width: 120.0625em;
  font-family: "/only screen and (min-width:120.0625em)/", sans-serif;
}

meta.foundation-data-attribute-namespace {
  font-family: false, sans-serif;
}

html,
body {
  height: 100%;
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  color: #222;
  cursor: auto;
  background: #fff;
}

a:hover {
  cursor: pointer;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.hide {
  display: none;
}

.invisible {
  visibility: hidden;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea {
  max-width: 100%;
  height: auto;
  min-height: 50px;
}

select {
  width: 100%;
  height: 2.3125rem;
  padding: 0.5rem;
  font-family: inherit;
  font-size: 0.875rem;
  line-height: normal;
  color: rgb(0 0 0 / 75%);
  appearance: none !important;
  background-color: #fafafa;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMTJweCIgeT0iMHB4IiB3aWR0aD0iMjRweCIgaGVpZ2h0PSIzcHgiIHZpZXdCb3g9IjAgMCA2IDMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDYgMyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+");
  background-repeat: no-repeat;
  background-position: 100% center;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
}

.row {
  width: 100%;
  max-width: 62.5rem;
  margin: 0 auto;
}

.row::before,
.row::after {
  display: table;
  content: " ";
}

.row::after {
  clear: both;
}

.row.collapse > .column,
.row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.row.collapse .row {
  margin-right: 0;
  margin-left: 0;
}

.row .row {
  width: auto;
  max-width: none;
  margin: 0 -0.9375rem;
}

.row .row::before,
.row .row::after {
  display: table;
  content: " ";
}

.row .row::after {
  clear: both;
}

.row .row.collapse {
  width: auto;
  max-width: none;
  margin: 0;
}

.row .row.collapse::before,
.row .row.collapse::after {
  display: table;
  content: " ";
}

.row .row.collapse::after {
  clear: both;
}

.column,
.columns {
  float: left;
  width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.column + .column:last-child,
.columns + .column:last-child,
.column + .columns:last-child,
.columns + .columns:last-child {
  float: right;
}

.column + .column.end,
.columns + .column.end,
.column + .columns.end,
.columns + .columns.end {
  float: left;
}

@media only screen {
  .small-push-0 {
    position: relative;
    right: auto;
    left: 0;
  }

  .small-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .small-push-1 {
    position: relative;
    right: auto;
    left: 8.3333%;
  }

  .small-pull-1 {
    position: relative;
    right: 8.3333%;
    left: auto;
  }

  .small-push-2 {
    position: relative;
    right: auto;
    left: 16.6667%;
  }

  .small-pull-2 {
    position: relative;
    right: 16.6667%;
    left: auto;
  }

  .small-push-3 {
    position: relative;
    right: auto;
    left: 25%;
  }

  .small-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .small-push-4 {
    position: relative;
    right: auto;
    left: 33.3333%;
  }

  .small-pull-4 {
    position: relative;
    right: 33.3333%;
    left: auto;
  }

  .small-push-5 {
    position: relative;
    right: auto;
    left: 41.6667%;
  }

  .small-pull-5 {
    position: relative;
    right: 41.6667%;
    left: auto;
  }

  .small-push-6 {
    position: relative;
    right: auto;
    left: 50%;
  }

  .small-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .small-push-7 {
    position: relative;
    right: auto;
    left: 58.3333%;
  }

  .small-pull-7 {
    position: relative;
    right: 58.3333%;
    left: auto;
  }

  .small-push-8 {
    position: relative;
    right: auto;
    left: 66.6667%;
  }

  .small-pull-8 {
    position: relative;
    right: 66.6667%;
    left: auto;
  }

  .small-push-9 {
    position: relative;
    right: auto;
    left: 75%;
  }

  .small-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .small-push-10 {
    position: relative;
    right: auto;
    left: 83.3333%;
  }

  .small-pull-10 {
    position: relative;
    right: 83.3333%;
    left: auto;
  }

  .small-push-11 {
    position: relative;
    right: auto;
    left: 91.6667%;
  }

  .small-pull-11 {
    position: relative;
    right: 91.6667%;
    left: auto;
  }

  .column,
  .columns {
    position: relative;
    float: left;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .small-1 {
    width: 8.3333%;
  }

  .small-2 {
    width: 16.6667%;
  }

  .small-3 {
    width: 25%;
  }

  .small-4 {
    width: 33.3333%;
  }

  .small-5 {
    width: 41.6667%;
  }

  .small-6 {
    width: 50%;
  }

  .small-7 {
    width: 58.3333%;
  }

  .small-8 {
    width: 66.6667%;
  }

  .small-9 {
    width: 75%;
  }

  .small-10 {
    width: 83.3333%;
  }

  .small-11 {
    width: 91.6667%;
  }

  .small-12 {
    width: 100%;
  }

  .small-offset-0 {
    margin-left: 0 !important;
  }

  .small-offset-1 {
    margin-left: 8.3333% !important;
  }

  .small-offset-2 {
    margin-left: 16.6667% !important;
  }

  .small-offset-3 {
    margin-left: 25% !important;
  }

  .small-offset-4 {
    margin-left: 33.3333% !important;
  }

  .small-offset-5 {
    margin-left: 41.6667% !important;
  }

  .small-offset-6 {
    margin-left: 50% !important;
  }

  .small-offset-7 {
    margin-left: 58.3333% !important;
  }

  .small-offset-8 {
    margin-left: 66.6667% !important;
  }

  .small-offset-9 {
    margin-left: 75% !important;
  }

  .small-offset-10 {
    margin-left: 83.3333% !important;
  }

  .small-offset-11 {
    margin-left: 91.6667% !important;
  }

  .small-reset-order {
    right: auto;
    left: auto;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }

  .column.small-centered,
  .columns.small-centered {
    float: none;
    margin-right: auto;
    margin-left: auto;
  }

  .column.small-uncentered,
  .columns.small-uncentered {
    float: left;
    margin-right: 0;
    margin-left: 0;
  }

  .column.small-centered:last-child,
  .columns.small-centered:last-child {
    float: none;
  }

  .column.small-uncentered:last-child,
  .columns.small-uncentered:last-child {
    float: left;
  }

  .column.small-uncentered.opposite,
  .columns.small-uncentered.opposite {
    float: right;
  }

  .row.small-collapse > .column,
  .row.small-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }

  .row.small-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }

  .row.small-uncollapse > .column,
  .row.small-uncollapse > .columns {
    float: left;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media only screen and (width >= 40.0625em) {
  .medium-push-0 {
    position: relative;
    right: auto;
    left: 0;
  }

  .medium-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .medium-push-1 {
    position: relative;
    right: auto;
    left: 8.3333%;
  }

  .medium-pull-1 {
    position: relative;
    right: 8.3333%;
    left: auto;
  }

  .medium-push-2 {
    position: relative;
    right: auto;
    left: 16.6667%;
  }

  .medium-pull-2 {
    position: relative;
    right: 16.6667%;
    left: auto;
  }

  .medium-push-3 {
    position: relative;
    right: auto;
    left: 25%;
  }

  .medium-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .medium-push-4 {
    position: relative;
    right: auto;
    left: 33.3333%;
  }

  .medium-pull-4 {
    position: relative;
    right: 33.3333%;
    left: auto;
  }

  .medium-push-5 {
    position: relative;
    right: auto;
    left: 41.6667%;
  }

  .medium-pull-5 {
    position: relative;
    right: 41.6667%;
    left: auto;
  }

  .medium-push-6 {
    position: relative;
    right: auto;
    left: 50%;
  }

  .medium-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .medium-push-7 {
    position: relative;
    right: auto;
    left: 58.3333%;
  }

  .medium-pull-7 {
    position: relative;
    right: 58.3333%;
    left: auto;
  }

  .medium-push-8 {
    position: relative;
    right: auto;
    left: 66.6667%;
  }

  .medium-pull-8 {
    position: relative;
    right: 66.6667%;
    left: auto;
  }

  .medium-push-9 {
    position: relative;
    right: auto;
    left: 75%;
  }

  .medium-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .medium-push-10 {
    position: relative;
    right: auto;
    left: 83.3333%;
  }

  .medium-pull-10 {
    position: relative;
    right: 83.3333%;
    left: auto;
  }

  .medium-push-11 {
    position: relative;
    right: auto;
    left: 91.6667%;
  }

  .medium-pull-11 {
    position: relative;
    right: 91.6667%;
    left: auto;
  }

  .column,
  .columns {
    position: relative;
    float: left;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .medium-1 {
    width: 8.3333%;
  }

  .medium-2 {
    width: 16.6667%;
  }

  .medium-3 {
    width: 25%;
  }

  .medium-4 {
    width: 33.3333%;
  }

  .medium-5 {
    width: 41.6667%;
  }

  .medium-6 {
    width: 50%;
  }

  .medium-7 {
    width: 58.3333%;
  }

  .medium-8 {
    width: 66.6667%;
  }

  .medium-9 {
    width: 75%;
  }

  .medium-10 {
    width: 83.3333%;
  }

  .medium-11 {
    width: 91.6667%;
  }

  .medium-12 {
    width: 100%;
  }

  .medium-offset-0 {
    margin-left: 0 !important;
  }

  .medium-offset-1 {
    margin-left: 8.3333% !important;
  }

  .medium-offset-2 {
    margin-left: 16.6667% !important;
  }

  .medium-offset-3 {
    margin-left: 25% !important;
  }

  .medium-offset-4 {
    margin-left: 33.3333% !important;
  }

  .medium-offset-5 {
    margin-left: 41.6667% !important;
  }

  .medium-offset-6 {
    margin-left: 50% !important;
  }

  .medium-offset-7 {
    margin-left: 58.3333% !important;
  }

  .medium-offset-8 {
    margin-left: 66.6667% !important;
  }

  .medium-offset-9 {
    margin-left: 75% !important;
  }

  .medium-offset-10 {
    margin-left: 83.3333% !important;
  }

  .medium-offset-11 {
    margin-left: 91.6667% !important;
  }

  .medium-reset-order {
    right: auto;
    left: auto;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }

  .column.medium-centered,
  .columns.medium-centered {
    float: none;
    margin-right: auto;
    margin-left: auto;
  }

  .column.medium-uncentered,
  .columns.medium-uncentered {
    float: left;
    margin-right: 0;
    margin-left: 0;
  }

  .column.medium-centered:last-child,
  .columns.medium-centered:last-child {
    float: none;
  }

  .column.medium-uncentered:last-child,
  .columns.medium-uncentered:last-child {
    float: left;
  }

  .column.medium-uncentered.opposite,
  .columns.medium-uncentered.opposite {
    float: right;
  }

  .row.medium-collapse > .column,
  .row.medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }

  .row.medium-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }

  .row.medium-uncollapse > .column,
  .row.medium-uncollapse > .columns {
    float: left;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .push-0 {
    position: relative;
    right: auto;
    left: 0;
  }

  .pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .push-1 {
    position: relative;
    right: auto;
    left: 8.3333%;
  }

  .pull-1 {
    position: relative;
    right: 8.3333%;
    left: auto;
  }

  .push-2 {
    position: relative;
    right: auto;
    left: 16.6667%;
  }

  .pull-2 {
    position: relative;
    right: 16.6667%;
    left: auto;
  }

  .push-3 {
    position: relative;
    right: auto;
    left: 25%;
  }

  .pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .push-4 {
    position: relative;
    right: auto;
    left: 33.3333%;
  }

  .pull-4 {
    position: relative;
    right: 33.3333%;
    left: auto;
  }

  .push-5 {
    position: relative;
    right: auto;
    left: 41.6667%;
  }

  .pull-5 {
    position: relative;
    right: 41.6667%;
    left: auto;
  }

  .push-6 {
    position: relative;
    right: auto;
    left: 50%;
  }

  .pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .push-7 {
    position: relative;
    right: auto;
    left: 58.3333%;
  }

  .pull-7 {
    position: relative;
    right: 58.3333%;
    left: auto;
  }

  .push-8 {
    position: relative;
    right: auto;
    left: 66.6667%;
  }

  .pull-8 {
    position: relative;
    right: 66.6667%;
    left: auto;
  }

  .push-9 {
    position: relative;
    right: auto;
    left: 75%;
  }

  .pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .push-10 {
    position: relative;
    right: auto;
    left: 83.3333%;
  }

  .pull-10 {
    position: relative;
    right: 83.3333%;
    left: auto;
  }

  .push-11 {
    position: relative;
    right: auto;
    left: 91.6667%;
  }

  .pull-11 {
    position: relative;
    right: 91.6667%;
    left: auto;
  }
}

@media only screen and (width >= 64.0625em) {
  .large-push-0 {
    position: relative;
    right: auto;
    left: 0;
  }

  .large-pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .large-push-1 {
    position: relative;
    right: auto;
    left: 8.3333%;
  }

  .large-pull-1 {
    position: relative;
    right: 8.3333%;
    left: auto;
  }

  .large-push-2 {
    position: relative;
    right: auto;
    left: 16.6667%;
  }

  .large-pull-2 {
    position: relative;
    right: 16.6667%;
    left: auto;
  }

  .large-push-3 {
    position: relative;
    right: auto;
    left: 25%;
  }

  .large-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .large-push-4 {
    position: relative;
    right: auto;
    left: 33.3333%;
  }

  .large-pull-4 {
    position: relative;
    right: 33.3333%;
    left: auto;
  }

  .large-push-5 {
    position: relative;
    right: auto;
    left: 41.6667%;
  }

  .large-pull-5 {
    position: relative;
    right: 41.6667%;
    left: auto;
  }

  .large-push-6 {
    position: relative;
    right: auto;
    left: 50%;
  }

  .large-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .large-push-7 {
    position: relative;
    right: auto;
    left: 58.3333%;
  }

  .large-pull-7 {
    position: relative;
    right: 58.3333%;
    left: auto;
  }

  .large-push-8 {
    position: relative;
    right: auto;
    left: 66.6667%;
  }

  .large-pull-8 {
    position: relative;
    right: 66.6667%;
    left: auto;
  }

  .large-push-9 {
    position: relative;
    right: auto;
    left: 75%;
  }

  .large-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .large-push-10 {
    position: relative;
    right: auto;
    left: 83.3333%;
  }

  .large-pull-10 {
    position: relative;
    right: 83.3333%;
    left: auto;
  }

  .large-push-11 {
    position: relative;
    right: auto;
    left: 91.6667%;
  }

  .large-pull-11 {
    position: relative;
    right: 91.6667%;
    left: auto;
  }

  .column,
  .columns {
    position: relative;
    float: left;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .large-1 {
    width: 8.3333%;
  }

  .large-2 {
    width: 16.6667%;
  }

  .large-3 {
    width: 25%;
  }

  .large-4 {
    width: 33.3333%;
  }

  .large-5 {
    width: 41.6667%;
  }

  .large-6 {
    width: 50%;
  }

  .large-7 {
    width: 58.3333%;
  }

  .large-8 {
    width: 66.6667%;
  }

  .large-9 {
    width: 75%;
  }

  .large-10 {
    width: 83.3333%;
  }

  .large-11 {
    width: 91.6667%;
  }

  .large-12 {
    width: 100%;
  }

  .large-offset-0 {
    margin-left: 0 !important;
  }

  .large-offset-1 {
    margin-left: 8.3333% !important;
  }

  .large-offset-2 {
    margin-left: 16.6667% !important;
  }

  .large-offset-3 {
    margin-left: 25% !important;
  }

  .large-offset-4 {
    margin-left: 33.3333% !important;
  }

  .large-offset-5 {
    margin-left: 41.6667% !important;
  }

  .large-offset-6 {
    margin-left: 50% !important;
  }

  .large-offset-7 {
    margin-left: 58.3333% !important;
  }

  .large-offset-8 {
    margin-left: 66.6667% !important;
  }

  .large-offset-9 {
    margin-left: 75% !important;
  }

  .large-offset-10 {
    margin-left: 83.3333% !important;
  }

  .large-offset-11 {
    margin-left: 91.6667% !important;
  }

  .large-reset-order {
    right: auto;
    left: auto;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }

  .column.large-centered,
  .columns.large-centered {
    float: none;
    margin-right: auto;
    margin-left: auto;
  }

  .column.large-uncentered,
  .columns.large-uncentered {
    float: left;
    margin-right: 0;
    margin-left: 0;
  }

  .column.large-centered:last-child,
  .columns.large-centered:last-child {
    float: none;
  }

  .column.large-uncentered:last-child,
  .columns.large-uncentered:last-child {
    float: left;
  }

  .column.large-uncentered.opposite,
  .columns.large-uncentered.opposite {
    float: right;
  }

  .row.large-collapse > .column,
  .row.large-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }

  .row.large-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }

  .row.large-uncollapse > .column,
  .row.large-uncollapse > .columns {
    float: left;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .push-0 {
    position: relative;
    right: auto;
    left: 0;
  }

  .pull-0 {
    position: relative;
    right: 0;
    left: auto;
  }

  .push-1 {
    position: relative;
    right: auto;
    left: 8.3333%;
  }

  .pull-1 {
    position: relative;
    right: 8.3333%;
    left: auto;
  }

  .push-2 {
    position: relative;
    right: auto;
    left: 16.6667%;
  }

  .pull-2 {
    position: relative;
    right: 16.6667%;
    left: auto;
  }

  .push-3 {
    position: relative;
    right: auto;
    left: 25%;
  }

  .pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .push-4 {
    position: relative;
    right: auto;
    left: 33.3333%;
  }

  .pull-4 {
    position: relative;
    right: 33.3333%;
    left: auto;
  }

  .push-5 {
    position: relative;
    right: auto;
    left: 41.6667%;
  }

  .pull-5 {
    position: relative;
    right: 41.6667%;
    left: auto;
  }

  .push-6 {
    position: relative;
    right: auto;
    left: 50%;
  }

  .pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .push-7 {
    position: relative;
    right: auto;
    left: 58.3333%;
  }

  .pull-7 {
    position: relative;
    right: 58.3333%;
    left: auto;
  }

  .push-8 {
    position: relative;
    right: auto;
    left: 66.6667%;
  }

  .pull-8 {
    position: relative;
    right: 66.6667%;
    left: auto;
  }

  .push-9 {
    position: relative;
    right: auto;
    left: 75%;
  }

  .pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .push-10 {
    position: relative;
    right: auto;
    left: 83.3333%;
  }

  .pull-10 {
    position: relative;
    right: 83.3333%;
    left: auto;
  }

  .push-11 {
    position: relative;
    right: auto;
    left: 91.6667%;
  }

  .pull-11 {
    position: relative;
    right: 91.6667%;
    left: auto;
  }
}

button,
.button {
  position: relative;
  display: inline-block;
  padding: 1rem 2rem 1.0625rem;
  margin: 0 0 1.25rem;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
  color: #fff;
  text-align: center;
  text-decoration: none;
  appearance: none;
  cursor: pointer;
  background-color: #2DB1FF;
  border-color: transparent;
  border-style: solid;
  border-width: 0;
  border-radius: 0;
  transition: background-color 300ms ease-out;
}

.button:hover,
.button:focus {
  color: #fff;
  background-color: #008EE2;
}

button.secondary,
.button.secondary {
  color: #fff;
  background-color: #868e96;
}

button.secondary:hover,
button.secondary:focus,
.button.secondary:hover,
.button.secondary:focus {
  background-color: #495057;
}

button.success,
.button.success {
  color: #fff;
  background-color: #12b886;
}

button.success:hover,
button.success:focus,
.button.success:hover,
.button.success:focus {
  color: #fff;
  background-color: #0ca678;
}

button.alert,
.button.alert {
  color: #fff;
  background-color: #fa5252;
}

button.alert:hover,
button.alert:focus,
.button.alert:hover,
.button.alert:focus {
  color: #fff;
  background-color: #cf2a0e;
}

button.warning,
.button.warning {
  color: #fff;
  background-color: #fd7e14;
}

button.warning:hover,
button.warning:focus,
.button.warning:hover,
.button.warning:focus {
  color: #fff;
  background-color: #cf6e0e;
}

button.info,
.button.info {
  color: #333;
  background-color: #a0d3e8;
}

button.info:hover,
button.info:focus,
.button.info:hover,
.button.info:focus {
  color: #fff;
  background-color: #61b6d9;
}

button.large,
.button.large {
  padding: 1.125rem 2.25rem 1.1875rem;
  font-size: 1.25rem;
}

button.small,
.button.small {
  padding: 0.875rem 1.75rem 0.9375rem;
  font-size: 0.8125rem;
}

button.tiny,
.button.tiny {
    padding: 0.55rem 1.05rem;
    font-size: 14px;
}

button.expand,
.button.expand {
  width: 100%;
  padding: 1rem 1rem 1.0625rem;
  font-size: 1rem;
}

button.left-align,
.button.left-align {
  text-align: left;
  text-indent: 0.75rem;
}

button.right-align,
.button.right-align {
  padding-right: 0.75rem;
  text-align: right;
}

button.radius,
.button.radius {
  border-radius: 3px;
}

button.round,
.button.round {
  border-radius: 1000px;
}

button.disabled:not(.mantine-Button-root),
button[disabled]:not(.mantine-Button-root),
.button.disabled:not(.mantine-Button-root),
.button[disabled]:not(.mantine-Button-root) {
  color: #fff;
  cursor: default;
  background-color: #2DB1FF;
  box-shadow: none;
  opacity: 0.7;
}

button.disabled:not(.mantine-Button-root):hover,
button.disabled:not(.mantine-Button-root):focus,
button[disabled]:not(.mantine-Button-root):hover,
button[disabled]:not(.mantine-Button-root):focus,
.button.disabled:not(.mantine-Button-root):hover,
.button.disabled:not(.mantine-Button-root):focus,
.button[disabled]:not(.mantine-Button-root):hover,
.button[disabled]:not(.mantine-Button-root):focus {
  background-color: #2DB1FF;
}

button.disabled.secondary:not(.mantine-Button-root),
button[disabled].secondary:not(.mantine-Button-root),
.button.disabled.secondary:not(.mantine-Button-root),
.button[disabled].secondary:not(.mantine-Button-root) {
  color: #333;
  cursor: default;
  background-color: #e7e7e7;
  box-shadow: none;
  opacity: 0.7;
}

button.disabled.secondary:not(.mantine-Button-root):hover,
button.disabled.secondary:not(.mantine-Button-root):focus,
button[disabled].secondary:not(.mantine-Button-root):hover,
button[disabled].secondary:not(.mantine-Button-root):focus,
.button.disabled.secondary:not(.mantine-Button-root):hover,
.button.disabled.secondary:not(.mantine-Button-root):focus,
.button[disabled].secondary:not(.mantine-Button-root):hover,
.button[disabled].secondary:not(.mantine-Button-root):focus {
  color: #333;
  background-color: #e7e7e7;
}

button.disabled.success:not(.mantine-Button-root),
button[disabled].success:not(.mantine-Button-root),
.button.disabled.success:not(.mantine-Button-root),
.button[disabled].success:not(.mantine-Button-root) {
  color: #fff;
  cursor: default;
  background-color: #12b886;
  box-shadow: none;
  opacity: 0.7;
}

button.disabled.success:not(.mantine-Button-root):hover,
button.disabled.success:not(.mantine-Button-root):focus,
button[disabled].success:not(.mantine-Button-root):hover,
button[disabled].success:not(.mantine-Button-root):focus,
.button.disabled.success:not(.mantine-Button-root):hover,
.button.disabled.success:not(.mantine-Button-root):focus,
.button[disabled].success:not(.mantine-Button-root):hover,
.button[disabled].success:not(.mantine-Button-root):focus {
  color: #fff;
  background-color: #12b886;
}

button.disabled.alert:not(.mantine-Button-root),
button[disabled].alert:not(.mantine-Button-root),
.button.disabled.alert:not(.mantine-Button-root),
.button[disabled].alert:not(.mantine-Button-root) {
  color: #fff;
  cursor: default;
  background-color: #fa5252;
  box-shadow: none;
  opacity: 0.7;
}

button.disabled.alert:not(.mantine-Button-root):hover,
button.disabled.alert:not(.mantine-Button-root):focus,
button[disabled].alert:not(.mantine-Button-root):hover,
button[disabled].alert:not(.mantine-Button-root):focus,
.button.disabled.alert:not(.mantine-Button-root):hover,
.button.disabled.alert:not(.mantine-Button-root):focus,
.button[disabled].alert:not(.mantine-Button-root):hover,
.button[disabled].alert:not(.mantine-Button-root):focus {
  color: #fff;
  background-color: #fa5252;
}

button.disabled.warning:not(.mantine-Button-root),
button[disabled].warning:not(.mantine-Button-root),
.button.disabled.warning:not(.mantine-Button-root),
.button[disabled].warning:not(.mantine-Button-root) {
  color: #fff;
  cursor: default;
  background-color: #fd7e14;
  box-shadow: none;
  opacity: 0.7;
}

button.disabled.warning:not(.mantine-Button-root):hover,
button.disabled.warning:not(.mantine-Button-root):focus,
button[disabled].warning:not(.mantine-Button-root):hover,
button[disabled].warning:not(.mantine-Button-root):focus,
.button.disabled.warning:not(.mantine-Button-root):hover,
.button.disabled.warning:not(.mantine-Button-root):focus,
.button[disabled].warning:not(.mantine-Button-root):hover,
.button[disabled].warning:not(.mantine-Button-root):focus {
  color: #fff;
  background-color: #fd7e14;
}

button.disabled.info:not(.mantine-Button-root),
button[disabled].info:not(.mantine-Button-root),
.button.disabled.info:not(.mantine-Button-root),
.button[disabled].info:not(.mantine-Button-root) {
  color: #333;
  cursor: default;
  background-color: #a0d3e8;
  box-shadow: none;
  opacity: 0.7;
}

button.disabled.info:not(.mantine-Button-root):hover,
button.disabled.info:not(.mantine-Button-root):focus,
button[disabled].info:not(.mantine-Button-root):hover,
button[disabled].info:not(.mantine-Button-root):focus,
.button.disabled.info:not(.mantine-Button-root):hover,
.button.disabled.info:not(.mantine-Button-root):focus,
.button[disabled].info:not(.mantine-Button-root):hover,
.button[disabled].info:not(.mantine-Button-root):focus {
  color: #fff;
  background-color: #a0d3e8;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

@media only screen and (width >= 40.0625em) {
  button,
  .button {
    display: inline-block;
  }
}

/* Standard Forms */
form {
  margin: 0 0 1rem;
}

/* Using forms within rows, we need to set some defaults */
form .row .row {
  margin: 0 -0.5rem;
}

form .row .row .column,
form .row .row .columns {
  padding: 0 0.5rem;
}

form .row .row.collapse {
  margin: 0;
}

form .row .row.collapse .column,
form .row .row.collapse .columns {
  padding: 0;
}

form .row .row.collapse input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

form .row input.column,
form .row input.columns,
form .row textarea.column,
form .row textarea.columns {
  padding-left: 0.5rem;
}

/* Label Styles */
label {
  display: block;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.5;
  color: #4d4d4d;
  cursor: pointer;

  /* Styles for required inputs */
}

label.right {
  float: none !important;
  text-align: right;
}

label.inline {
  padding: 0.5625rem 0;
  margin: 0 0 1rem;
}

label small {
  color: #676767;
  text-transform: capitalize;
}

/* We use this to get basic styling on all basic form elements */
input:not([type]),
input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea {
  box-sizing: border-box;
  display: block;
  width: 100%;
  height: 2.3125rem;
  padding: 0.5rem;
  margin: 0 0 1rem;
  font-family: inherit;
  font-size: 0.875rem;
  color: rgb(0 0 0 / 75%);
  appearance: none;
  background-color: #fff;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  border-radius: 0;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  transition: border-color 0.15s linear, background 0.15s linear;
}

input:not([type]):focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="color"]:focus,
textarea:focus {
  background: #fafafa;
  border-color: #999;
  outline: none;
}

input:not([type]):disabled,
input[type="text"]:disabled,
input[type="password"]:disabled,
input[type="date"]:disabled,
input[type="datetime"]:disabled,
input[type="datetime-local"]:disabled,
input[type="month"]:disabled,
input[type="week"]:disabled,
input[type="email"]:disabled,
input[type="number"]:disabled,
input[type="search"]:disabled,
input[type="tel"]:disabled,
input[type="time"]:disabled,
input[type="url"]:disabled,
input[type="color"]:disabled,
textarea:disabled {
  cursor: default;
  background-color: #ddd;
}

input:not([type])[disabled],
fieldset[disabled] input:not([type]),
input[type="text"][disabled],
input[type="text"][readonly],
fieldset[disabled] input[type="text"],
input[type="password"][disabled],
input[type="password"][readonly],
fieldset[disabled] input[type="password"],
input[type="date"][disabled],
input[type="date"][readonly],
fieldset[disabled] input[type="date"],
input[type="datetime"][disabled],
input[type="datetime"][readonly],
fieldset[disabled] input[type="datetime"],
input[type="datetime-local"][disabled],
input[type="datetime-local"][readonly],
fieldset[disabled] input[type="datetime-local"],
input[type="month"][disabled],
input[type="month"][readonly],
fieldset[disabled] input[type="month"],
input[type="week"][disabled],
input[type="week"][readonly],
fieldset[disabled] input[type="week"],
input[type="email"][disabled],
input[type="email"][readonly],
fieldset[disabled] input[type="email"],
input[type="number"][disabled],
input[type="number"][readonly],
fieldset[disabled] input[type="number"],
input[type="search"][disabled],
fieldset[disabled] input[type="search"],
input[type="tel"][disabled],
input[type="tel"][readonly],
fieldset[disabled] input[type="tel"],
input[type="time"][disabled],
input[type="time"][readonly],
fieldset[disabled] input[type="time"],
input[type="url"][disabled],
input[type="url"][readonly],
fieldset[disabled] input[type="url"],
input[type="color"][disabled],
input[type="color"][readonly],
fieldset[disabled] input[type="color"],
textarea[disabled],
textarea[readonly],
fieldset[disabled] textarea {
  cursor: default;
  background-color: #ddd;
}

input:not([type]).radius,
input[type="text"].radius,
input[type="password"].radius,
input[type="date"].radius,
input[type="datetime"].radius,
input[type="datetime-local"].radius,
input[type="month"].radius,
input[type="week"].radius,
input[type="email"].radius,
input[type="number"].radius,
input[type="search"].radius,
input[type="tel"].radius,
input[type="time"].radius,
input[type="url"].radius,
input[type="color"].radius,
textarea.radius {
  border-radius: 3px;
}

input[type="submit"] {
  appearance: none;
  border-radius: 0;
}

/* Respect enforced amount of rows for textarea */
textarea[rows] {
  height: auto;
}

select::-ms-expand {
  display: none;
}

select.radius {
  border-radius: 3px;
}

select:focus {
  background-color: #f3f3f3;
  border-color: #999;
}

select:disabled {
  cursor: default;
  background-color: #ddd;
}

select[multiple] {
  height: auto;
}

/* Adjust margin for form elements below */
input[type="file"],
input[type="checkbox"],
input[type="radio"],
select {
  margin: 0 0 1rem;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 0;
  margin-left: 0.5rem;
  vertical-align: baseline;
}

/* Normalize file input width */
input[type="file"] {
  width: 100%;
}

/* HTML5 Number spinners settings */

/* We add basic fieldset styling */
fieldset {
  padding: 1.25rem;
  margin: 1.125rem 0;
  border: 1px solid #ddd;
}

fieldset legend {
  padding: 0 0.1875rem;
  margin: 0 0 0 -0.1875rem;
  font-weight: bold;
}

/* Error Handling */
[data-abide] .error small.error,
[data-abide] .error span.error,
[data-abide] span.error,
[data-abide] small.error {
  display: block;
  padding: 0.375rem 0.5625rem 0.5625rem;
  margin-top: -1px;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: normal;
  color: #fff;
  background: #fa5252;
}

[data-abide] span.error,
[data-abide] small.error {
  display: none;
}

span.error,
small.error {
  display: block;
  padding: 0.375rem 0.5625rem 0.5625rem;
  margin-top: -1px;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: normal;
  color: #fff;
  background: #fa5252;
}

.error input,
.error textarea,
.error select {
  margin-bottom: 0;
}

.error input[type="checkbox"],
.error input[type="radio"] {
  margin-bottom: 1rem;
}

.error label,
.error label.error {
  color: #fa5252;
}

.error small.error {
  display: block;
  padding: 0.375rem 0.5625rem 0.5625rem;
  margin-top: -1px;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: normal;
  color: #fff;
  background: #fa5252;
}

.error > label > small {
  display: inline;
  padding: 0;
  margin: 0;
  font-size: 60%;
  font-style: normal;
  color: #676767;
  text-transform: capitalize;
  background: transparent;
}

.error span.error-message {
  display: block;
}

input.error,
textarea.error,
select.error {
  margin-bottom: 0;
}

label.error {
  color: #fa5252;
}

meta.foundation-mq-topbar {
  width: 40.0625em;
  font-family: "/only screen and (min-width:40.0625em)/", sans-serif;
}

/* Wrapped around .top-bar to contain to grid width */
.contain-to-grid {
  width: 100%;
  background: #333;
}

.contain-to-grid .top-bar {
  margin-bottom: 0;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
}

.fixed.expanded:not(.top-bar) {
  width: 100%;
  height: auto;
  max-height: 100%;
  overflow-y: auto;
}

.fixed.expanded:not(.top-bar) .title-area {
  position: fixed;
  z-index: 99;
  width: 100%;
}

.fixed.expanded:not(.top-bar) .top-bar-section {
  z-index: 98;
  margin-top: 2.8125rem;
}

.top-bar {
  position: relative;
  height: 2.8125rem;
  margin-bottom: 0;
  overflow: hidden;
  line-height: 2.8125rem;
  background: #333;
}

.top-bar ul {
  margin-bottom: 0;
  list-style: none;
}

.top-bar .row {
  max-width: none;
}

.top-bar form,
.top-bar input,
.top-bar select {
  margin-bottom: 0;
}

.top-bar input,
.top-bar select {
  height: 1.75rem;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  font-size: 0.75rem;
}

.top-bar .button,
.top-bar button {
  padding-top: 0.4125rem;
  padding-bottom: 0.4125rem;
  margin-bottom: 0;
  font-size: 0.75rem;
}

@media only screen and (width <= 40em) {
  .top-bar .button,
  .top-bar button {
    position: relative;
    top: -1px;
  }
}

.top-bar .title-area {
  position: relative;
  margin: 0;
}

.top-bar .name {
  height: 2.8125rem;
  margin: 0;
  font-size: 16px;
}

.top-bar .name h1,
.top-bar .name h2,
.top-bar .name h3,
.top-bar .name h4,
.top-bar .name p,
.top-bar .name span {
  margin: 0;
  font-size: 1.0625rem;
  line-height: 2.8125rem;
}

.top-bar .name h1 a,
.top-bar .name h2 a,
.top-bar .name h3 a,
.top-bar .name h4 a,
.top-bar .name p a,
.top-bar .name span a {
  display: block;
  width: 75%;
  padding: 0 0.9375rem;
  font-weight: normal;
  color: #fff;
}

.top-bar .toggle-topbar {
  position: absolute;
  top: 0;
  right: 0;
}

.top-bar .toggle-topbar a {
  position: relative;
  display: block;
  height: 2.8125rem;
  padding: 0 0.9375rem;
  font-size: 0.8125rem;
  font-weight: bold;
  line-height: 2.8125rem;
  color: #fff;
  text-transform: uppercase;
}

.top-bar .toggle-topbar.menu-icon {
  top: 50%;
  margin-top: -16px;
}

.top-bar .toggle-topbar.menu-icon a {
  position: relative;
  height: 34px;
  padding: 0 2.5rem 0 0.9375rem;
  line-height: 33px;
  color: #fff;
}

.top-bar .toggle-topbar.menu-icon a span::after {
  position: absolute;
  top: 50%;
  right: 0.9375rem;
  display: block;
  width: 16px;
  height: 0;
  margin-top: -8px;
  content: "";
  box-shadow: 0 0 0 1px #fff, 0 7px 0 1px #fff, 0 14px 0 1px #fff;
}

.top-bar .toggle-topbar.menu-icon a span:hover::after {
  box-shadow: 0 0 0 1px "", 0 7px 0 1px "", 0 14px 0 1px "";
}

.top-bar.expanded {
  height: auto;
  background: transparent;
}

.top-bar.expanded .title-area {
  background: #333;
}

.top-bar.expanded .toggle-topbar a {
  color: #888;
}

.top-bar.expanded .toggle-topbar a span::after {
  box-shadow: 0 0 0 1px #888, 0 7px 0 1px #888, 0 14px 0 1px #888;
}

.top-bar-section {
  position: relative;
  left: 0;
  width: auto;
  transition: left 300ms ease-out;
}

.top-bar-section ul {
  display: block;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  font-size: 16px;
}

.top-bar-section .divider,
.top-bar-section [role="separator"] {
  width: 100%;
  height: 1px;
  clear: both;
  border-top: solid 1px #1a1a1a;
}

.top-bar-section ul li {
  background: #333;
}

.top-bar-section ul li > a {
  display: block;
  width: 100%;
  padding: 12px 0 12px 0.9375rem;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 0.8125rem;
  font-weight: normal;
  color: #fff;
  text-transform: none;
}

.top-bar-section ul li > a.button {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  font-size: 0.8125rem;
  color: #fff;
  background-color: #2DB1FF;
  border-color: #008EE2;
}

.top-bar-section ul li > a.button:hover,
.top-bar-section ul li > a.button:focus {
  color: #fff;
  background-color: #008EE2;
}

.top-bar-section ul li > a.button.secondary {
  color: #333;
  background-color: #e7e7e7;
  border-color: #b9b9b9;
}

.top-bar-section ul li > a.button.secondary:hover,
.top-bar-section ul li > a.button.secondary:focus {
  color: #333;
  background-color: #b9b9b9;
}

.top-bar-section ul li > a.button.success {
  color: #fff;
  background-color: #12b886;
  border-color: #0ca678;
}

.top-bar-section ul li > a.button.success:hover,
.top-bar-section ul li > a.button.success:focus {
  color: #fff;
  background-color: #0ca678;
}

.top-bar-section ul li > a.button.alert {
  color: #fff;
  background-color: #fa5252;
  border-color: #cf2a0e;
}

.top-bar-section ul li > a.button.alert:hover,
.top-bar-section ul li > a.button.alert:focus {
  color: #fff;
  background-color: #cf2a0e;
}

.top-bar-section ul li > a.button.warning {
  color: #fff;
  background-color: #fd7e14;
  border-color: #cf6e0e;
}

.top-bar-section ul li > a.button.warning:hover,
.top-bar-section ul li > a.button.warning:focus {
  color: #fff;
  background-color: #cf6e0e;
}

.top-bar-section ul li > a.button.info {
  color: #333;
  background-color: #a0d3e8;
  border-color: #61b6d9;
}

.top-bar-section ul li > a.button.info:hover,
.top-bar-section ul li > a.button.info:focus {
  color: #fff;
  background-color: #61b6d9;
}

.top-bar-section ul li > button {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  font-size: 0.8125rem;
  color: #fff;
  background-color: #2DB1FF;
  border-color: #008EE2;
}

.top-bar-section ul li > button:hover,
.top-bar-section ul li > button:focus {
  color: #fff;
  background-color: #008EE2;
}

.top-bar-section ul li > button.secondary {
  color: #333;
  background-color: #e7e7e7;
  border-color: #b9b9b9;
}

.top-bar-section ul li > button.secondary:hover,
.top-bar-section ul li > button.secondary:focus {
  color: #333;
  background-color: #b9b9b9;
}

.top-bar-section ul li > button.success {
  color: #fff;
  background-color: #12b886;
  border-color: #0ca678;
}

.top-bar-section ul li > button.success:hover,
.top-bar-section ul li > button.success:focus {
  color: #fff;
  background-color: #0ca678;
}

.top-bar-section ul li > button.alert {
  color: #fff;
  background-color: #fa5252;
  border-color: #cf2a0e;
}

.top-bar-section ul li > button.alert:hover,
.top-bar-section ul li > button.alert:focus {
  color: #fff;
  background-color: #cf2a0e;
}

.top-bar-section ul li > button.warning {
  color: #fff;
  background-color: #fd7e14;
  border-color: #cf6e0e;
}

.top-bar-section ul li > button.warning:hover,
.top-bar-section ul li > button.warning:focus {
  color: #fff;
  background-color: #cf6e0e;
}

.top-bar-section ul li > button.info {
  color: #333;
  background-color: #a0d3e8;
  border-color: #61b6d9;
}

.top-bar-section ul li > button.info:hover,
.top-bar-section ul li > button.info:focus {
  color: #fff;
  background-color: #61b6d9;
}

.top-bar-section ul li:hover:not(.has-form) > a {
  color: #fff;
  background-color: #555;
}

.top-bar-section ul li.active > a {
  color: #fff;
  background: #2DB1FF;
}

.top-bar-section ul li.active > a:hover {
  color: #fff;
  background: #0078a0;
}

.top-bar-section .has-form {
  padding: 0.9375rem;
}

.top-bar-section .has-dropdown {
  position: relative;
}

.top-bar-section .has-dropdown > a::after {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 0;
  height: 0;
  margin-top: -4.5px;
  margin-right: 0.9375rem;
  content: "";
  border: 5px inset transparent;
  border-left: solid rgb(255 255 255 / 40%);
}

.top-bar-section .has-dropdown.moved {
  position: static;
}

.top-bar-section .has-dropdown.moved > .dropdown {
  position: static !important;
  position: absolute !important;
  display: block;
  width: auto;
  width: 100%;
  height: auto;
  overflow: visible;
  clip: auto;
}

.top-bar-section .has-dropdown.moved > a::after {
  display: none;
}

.top-bar-section .dropdown {
  position: absolute !important;
  top: 0;
  left: 100%;
  z-index: 99;
  display: block;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.top-bar-section .dropdown li {
  width: 100%;
  height: auto;
}

.top-bar-section .dropdown li a {
  padding: 8px 0.9375rem;
  font-weight: normal;
}

.top-bar-section .dropdown li a.parent-link {
  font-weight: normal;
}

.top-bar-section .dropdown li.title h5,
.top-bar-section .dropdown li.parent-link {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.125rem;
}

.top-bar-section .dropdown li.title h5 a,
.top-bar-section .dropdown li.parent-link a {
  display: block;
  color: #fff;
}

.top-bar-section .dropdown li.title h5 a:hover,
.top-bar-section .dropdown li.parent-link a:hover {
  background: none;
}

.top-bar-section .dropdown li.has-form {
  padding: 8px 0.9375rem;
}

.top-bar-section .dropdown li .button,
.top-bar-section .dropdown li button {
  top: auto;
}

.top-bar-section .dropdown label {
  padding: 8px 0.9375rem 2px;
  margin-bottom: 0;
  font-size: 0.625rem;
  font-weight: bold;
  color: #777;
  text-transform: uppercase;
}

.js-generated {
  display: block;
}

@media only screen and (width >= 40.0625em) {
  .top-bar {
    overflow: visible;
    background: #333;
  }

  .top-bar::before,
  .top-bar::after {
    display: table;
    content: " ";
  }

  .top-bar::after {
    clear: both;
  }

  .top-bar .toggle-topbar {
    display: none;
  }

  .top-bar .title-area {
    float: left;
  }

  .top-bar .name h1 a,
  .top-bar .name h2 a,
  .top-bar .name h3 a,
  .top-bar .name h4 a,
  .top-bar .name h5 a,
  .top-bar .name h6 a {
    width: auto;
  }

  .top-bar input,
  .top-bar select,
  .top-bar .button,
  .top-bar button {
    position: relative;
    top: 0.5313rem;
    height: 1.75rem;
    font-size: 0.875rem;
  }

  .top-bar .has-form > .button,
  .top-bar .has-form > button {
    position: relative;
    top: 0.5313rem;
    height: 1.75rem;
    font-size: 0.875rem;
  }

  .top-bar.expanded {
    background: #333;
  }

  .contain-to-grid .top-bar {
    max-width: 62.5rem;
    margin: 0 auto;
  }

  .top-bar-section {
    left: 0 !important;
    transition: none 0 0;
  }

  .top-bar-section ul {
    display: inline;
    width: auto;
    height: auto !important;
  }

  .top-bar-section ul li {
    float: left;
  }

  .top-bar-section ul li .js-generated {
    display: none;
  }

  .top-bar-section li.hover > a:not(.button) {
    color: #fff;
    background-color: #555;
  }

  .top-bar-section li:not(.has-form) a:not(.button) {
    padding: 0 0.9375rem;
    line-height: 2.8125rem;
    background: #333;
  }

  .top-bar-section li:not(.has-form) a:not(.button):hover {
    background-color: #555;
  }

  .top-bar-section li.active:not(.has-form) a:not(.button) {
    padding: 0 0.9375rem;
    line-height: 2.8125rem;
    color: #fff;
    background: #2DB1FF;
  }

  .top-bar-section li.active:not(.has-form) a:not(.button):hover {
    color: #fff;
    background: #0078a0;
  }

  .top-bar-section .has-dropdown > a {
    padding-right: 2.1875rem !important;
  }

  .top-bar-section .has-dropdown > a::after {
    top: 1.4063rem;
    display: block;
    width: 0;
    height: 0;
    margin-top: -2.5px;
    content: "";
    border: 5px inset transparent;
    border-top: solid rgb(255 255 255 / 40%);
  }

  .top-bar-section .has-dropdown.moved {
    position: relative;
  }

  .top-bar-section .has-dropdown.moved > .dropdown {
    position: absolute !important;
    display: block;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  .top-bar-section .has-dropdown.hover > .dropdown,
  .top-bar-section .has-dropdown.not-click:hover > .dropdown {
    position: static !important;
    position: absolute !important;
    display: block;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
  }

  .top-bar-section .has-dropdown > a:focus + .dropdown {
    position: static !important;
    position: absolute !important;
    display: block;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
  }

  .top-bar-section .has-dropdown .dropdown li.has-dropdown > a::after {
    top: 0.1875rem;
    right: 5px;
    content: "\00bb";
    border: none;
  }

  .top-bar-section .dropdown {
    top: auto;
    left: 0;
    min-width: 100%;
    background: transparent;
  }

  .top-bar-section .dropdown li a {
    padding: 12px 0.9375rem;
    line-height: 2.8125rem;
    color: #fff;
    white-space: nowrap;
    background: #333;
  }

  .top-bar-section .dropdown li:not(.has-form, .active) > a:not(.button) {
    color: #fff;
    background: #333;
  }

  .top-bar-section .dropdown li:not(.has-form, .active):hover > a:not(.button) {
    color: #fff;
    background-color: #555;
  }

  .top-bar-section .dropdown li label {
    white-space: nowrap;
    background: #333;
  }

  .top-bar-section .dropdown li .dropdown {
    top: 0;
    left: 100%;
  }

  .top-bar-section > ul > .divider,
  .top-bar-section > ul > [role="separator"] {
    width: 0;
    height: 2.8125rem;
    clear: none;
    border-top: none;
    border-right: solid 1px #4e4e4e;
    border-bottom: none;
  }

  .top-bar-section .has-form {
    height: 2.8125rem;
    padding: 0 0.9375rem;
    background: #333;
  }

  .top-bar-section .right li .dropdown {
    right: 0;
    left: auto;
  }

  .top-bar-section .right li .dropdown li .dropdown {
    right: 100%;
  }

  .top-bar-section .left li .dropdown {
    right: auto;
    left: 0;
  }

  .top-bar-section .left li .dropdown li .dropdown {
    left: 100%;
  }

  .no-js .top-bar-section ul li:hover > a {
    color: #fff;
    background-color: #555;
  }

  .no-js .top-bar-section ul li:active > a {
    color: #fff;
    background: #2DB1FF;
  }

  .no-js .top-bar-section .has-dropdown:hover > .dropdown {
    position: static !important;
    position: absolute !important;
    display: block;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
  }

  .no-js .top-bar-section .has-dropdown > a:focus + .dropdown {
    position: static !important;
    position: absolute !important;
    display: block;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
  }
}

.alert-box {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0.625rem 0.625rem 0.625rem 1.375rem;
    margin-bottom: 1.25rem;
    font-size: 14px;
    font-weight: 500;
    color: #25262b;
    background-color: #FFF;
    border: 1px solid #F1F3F5;
    border-radius: 0.25rem;
    box-shadow: rgb(0 0 0 / 5%) 0 0.0625rem 0.1875rem, rgb(0 0 0 / 5%) 0 1.75rem 1.4375rem -0.4375rem, rgb(0 0 0 / 4%) 0 0.75rem 0.75rem -0.4375rem;
    transition: opacity 300ms ease-out;
}

.alert-box::before {
    position: absolute;
    top: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 0.375rem;
    content: "";
    background-color: #2DB1FF;
    border-radius: 0.25rem;
}

.alert-box .close {
  position: absolute;
  top: 50%;
  right: 0.25rem;
  padding: 0 6px 4px;
  margin-top: -0.6875rem;
  font-size: 1.375rem;
  line-height: 0.9;
  color: #868E96;
  background: inherit;
  opacity: 0.3;
}

.alert-box .close:hover,
.alert-box .close:focus {
  opacity: 0.5;
}

.alert-box.success::before {
    background-color: #12B886;
}

.alert-box.alert::before {
    background-color: #FA5252;
}

.alert-box.warning::before {
  background-color: #fd7e14;
}

.alert-box.info::before {
  background-color: #2DB1FF;
}

.alert-box.alert-close {
  opacity: 0;
}

.inline-list {
  padding: 0;
  margin: 0 0 1.0625rem -1.375rem;
  overflow: hidden;
  list-style: none;
}

.inline-list > li {
  display: block;
  float: left;
  margin-left: 1.375rem;
  list-style: none;
}

.inline-list > li > * {
  display: block;
}

/* Panels */
.panel {
  padding: 1.25rem;
  margin-bottom: 1.25rem;
  color: #333;
  background: #f2f2f2;
  border-color: #d8d8d8;
  border-style: solid;
  border-width: 1px;
}

.panel > :first-child {
  margin-top: 0;
}

.panel > :last-child {
  margin-bottom: 0;
}

.panel h1,
.panel h2,
.panel h3,
.panel h4,
.panel h5,
.panel h6,
.panel p,
.panel li,
.panel dl {
  color: #333;
}

.panel h1,
.panel h2,
.panel h3,
.panel h4,
.panel h5,
.panel h6 {
  margin-bottom: 0.625rem;
  line-height: 1;
}

.panel h1.subheader,
.panel h2.subheader,
.panel h3.subheader,
.panel h4.subheader,
.panel h5.subheader,
.panel h6.subheader {
  line-height: 1.4;
}

.panel.callout {
  padding: 1.25rem;
  margin-bottom: 1.25rem;
  color: #333;
  background: #ecfaff;
  border-color: #d8d8d8;
  border-style: solid;
  border-width: 1px;
}

.panel.callout > :first-child {
  margin-top: 0;
}

.panel.callout > :last-child {
  margin-bottom: 0;
}

.panel.callout h1,
.panel.callout h2,
.panel.callout h3,
.panel.callout h4,
.panel.callout h5,
.panel.callout h6,
.panel.callout p,
.panel.callout li,
.panel.callout dl {
  color: #333;
}

.panel.callout h1,
.panel.callout h2,
.panel.callout h3,
.panel.callout h4,
.panel.callout h5,
.panel.callout h6 {
  margin-bottom: 0.625rem;
  line-height: 1;
}

.panel.callout h1.subheader,
.panel.callout h2.subheader,
.panel.callout h3.subheader,
.panel.callout h4.subheader,
.panel.callout h5.subheader,
.panel.callout h6.subheader {
  line-height: 1.4;
}

.panel.callout a:not(.button) {
  color: #2DB1FF;
}

.panel.callout a:not(.button):hover,
.panel.callout a:not(.button):focus {
  color: #0078a0;
}

.panel.radius {
  border-radius: 3px;
}

.dropdown.button,
button.dropdown {
  position: relative;
  padding-right: 3.5625rem;
}

.dropdown.button::after,
button.dropdown::after {
  position: absolute;
  top: 50%;
  right: 1.4063rem;
  display: block;
  width: 0;
  height: 0;
  margin-top: -0.1563rem;
  content: "";
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 0.375rem;
}

.dropdown.button.tiny,
button.dropdown.tiny {
  padding-right: 2.625rem;
}

.dropdown.button.tiny::after,
button.dropdown.tiny::after {
  right: 1.125rem;
  margin-top: -0.125rem;
  border-color: #fff transparent transparent;
  border-width: 0.375rem;
}

.dropdown.button.small,
button.dropdown.small {
  padding-right: 3.0625rem;
}

.dropdown.button.small::after,
button.dropdown.small::after {
  right: 1.3125rem;
  margin-top: -0.1563rem;
  border-color: #fff transparent transparent;
  border-width: 0.4375rem;
}

.dropdown.button.large,
button.dropdown.large {
  padding-right: 3.625rem;
}

.dropdown.button.large::after,
button.dropdown.large::after {
  right: 1.7188rem;
  margin-top: -0.1563rem;
  border-color: #fff transparent transparent;
  border-width: 0.3125rem;
}

.dropdown.button.secondary::after,
button.dropdown.secondary::after {
  border-color: #333 transparent transparent;
}

/* Image Thumbnails */
.th {
  display: inline-block;
  max-width: 100%;
  line-height: 0;
  border: solid 4px #fff;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 20%);
  transition: all 200ms ease-out;
}

.th:hover,
.th:focus {
  box-shadow: 0 0 6px 1px rgb(0 140 186 / 50%);
}

.th.radius {
  border-radius: 3px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.tabs {
  margin-bottom: 0 !important;
  margin-left: 0;
}

.tabs::before,
.tabs::after {
  display: table;
  content: " ";
}

.tabs::after {
  clear: both;
}

.tabs dd,
.tabs .tab-title {
  position: relative;
  float: left;
  margin-bottom: 0 !important;
  list-style: none;
}

.tabs dd > a,
.tabs .tab-title > a {
  display: block;
  padding: 1rem 2rem;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 1rem;
  color: #222;
  background-color: #efefef;
}

.tabs dd > a:hover,
.tabs .tab-title > a:hover {
  background-color: #e1e1e1;
}

.tabs dd.active > a,
.tabs .tab-title.active > a {
  color: #222;
  background-color: #fff;
}

.tabs.radius dd:first-child a,
.tabs.radius .tab:first-child a {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.tabs.radius dd:last-child a,
.tabs.radius .tab:last-child a {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.tabs.vertical dd,
.tabs.vertical .tab-title {
  position: inherit;
  top: auto;
  display: block;
  float: none;
}

.tabs-content {
  width: 100%;
  margin-bottom: 1.5rem;
}

.tabs-content::before,
.tabs-content::after {
  display: table;
  content: " ";
}

.tabs-content::after {
  clear: both;
}

.tabs-content > .content {
  display: none;
  float: left;
  width: 100%;
  padding: 0.9375rem 0;
}

.tabs-content > .content.active {
  display: block;
  float: none;
}

.tabs-content > .content.contained {
  padding: 0.9375rem;
}

.tabs-content.vertical {
  display: block;
}

.tabs-content.vertical > .content {
  padding: 0 0.9375rem;
}

@media only screen and (width >= 40.0625em) {
  .tabs.vertical {
    float: left;
    width: 20%;
    max-width: 20%;
    margin: 0;
    margin-bottom: 1.25rem !important;
  }

  .tabs-content.vertical {
    float: left;
    width: 80%;
    max-width: 80%;
    padding-left: 1rem;
    margin-left: -1px;
  }
}

.no-js .tabs-content > .content {
  display: block;
  float: none;
}

ul.pagination {
  display: block;
  min-height: 1.5rem;
  margin-left: -0.3125rem;
}

ul.pagination li {
  display: block;
  float: left;
  height: 1.5rem;
  margin-left: 0.3125rem;
  font-size: 0.875rem;
  color: #222;
}

ul.pagination li a,
ul.pagination li button {
  display: block;
  padding: 0.0625rem 0.625rem;
  font-size: 1em;
  font-weight: normal;
  line-height: inherit;
  color: #999;
  background: none;
  border-radius: 3px;
  transition: background-color 300ms ease-out;
}

ul.pagination li:hover a,
ul.pagination li a:focus,
ul.pagination li:hover button,
ul.pagination li button:focus {
  background: #e6e6e6;
}

ul.pagination li.unavailable a,
ul.pagination li.unavailable button {
  color: #999;
  pointer-events: none;
  cursor: default;
}

ul.pagination li.unavailable:hover a,
ul.pagination li.unavailable a:focus,
ul.pagination li.unavailable:hover button,
ul.pagination li.unavailable button:focus {
  background: transparent;
}

ul.pagination li.current a,
ul.pagination li.current button {
  font-weight: bold;
  color: #fff;
  cursor: default;
  background: #2DB1FF;
}

ul.pagination li.current a:hover,
ul.pagination li.current a:focus,
ul.pagination li.current button:hover,
ul.pagination li.current button:focus {
  background: #2DB1FF;
}

/* Pagination centred wrapper */
.pagination-centered {
  text-align: center;
}

.pagination-centered ul.pagination li {
  display: inline-block;
  float: none;
}

.side-nav {
  display: block;
  padding: 0.875rem 0;
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  list-style-position: outside;
  list-style-type: none;
}

.side-nav li {
  margin: 0 0 0.4375rem;
  font-size: 0.875rem;
  font-weight: normal;
}

.side-nav li a:not(.button) {
  display: block;
  padding: 0.4375rem 0.875rem;
  margin: 0;
  color: #2DB1FF;
}

.side-nav li a:not(.button):hover,
.side-nav li a:not(.button):focus {
  color: #1cc7ff;
  background: rgb(0 0 0 / 2.5%);
}

.side-nav li a:not(.button):active {
  color: #1cc7ff;
}

.side-nav li.active > a:first-child:not(.button) {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  color: #1cc7ff;
}

.side-nav li.divider {
  height: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid;
  border-top-color: #e6e6e6;
}

.side-nav li.heading {
  font-size: 0.875rem;
  font-weight: bold;
  color: #2DB1FF;
  text-transform: uppercase;
}

.accordion {
  margin-bottom: 0;
  margin-left: 0;
}

.accordion::before,
.accordion::after {
  display: table;
  content: " ";
}

.accordion::after {
  clear: both;
}

.accordion .accordion-navigation,
.accordion dd {
  display: block;
  margin-bottom: 0 !important;
}

.accordion .accordion-navigation.active > a,
.accordion dd.active > a {
  color: #222;
  background: #e8e8e8;
}

.accordion .accordion-navigation > a,
.accordion dd > a {
  display: block;
  padding: 1rem;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 1rem;
  color: #222;
  background: #efefef;
}

.accordion .accordion-navigation > a:hover,
.accordion dd > a:hover {
  background: #e3e3e3;
}

.accordion .accordion-navigation > .content,
.accordion dd > .content {
  display: none;
  padding: 0.9375rem;
}

.accordion .accordion-navigation > .content.active,
.accordion dd > .content.active {
  display: block;
  background: #fff;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

@media only screen and (width <= 40em) {
  .small-only-text-left {
    text-align: left !important;
  }

  .small-only-text-right {
    text-align: right !important;
  }

  .small-only-text-center {
    text-align: center !important;
  }

  .small-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen {
  .small-text-left {
    text-align: left !important;
  }

  .small-text-right {
    text-align: right !important;
  }

  .small-text-center {
    text-align: center !important;
  }

  .small-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (width >= 40.0625em) and (width <= 64em) {
  .medium-only-text-left {
    text-align: left !important;
  }

  .medium-only-text-right {
    text-align: right !important;
  }

  .medium-only-text-center {
    text-align: center !important;
  }

  .medium-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (width >= 40.0625em) {
  .medium-text-left {
    text-align: left !important;
  }

  .medium-text-right {
    text-align: right !important;
  }

  .medium-text-center {
    text-align: center !important;
  }

  .medium-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (width >= 64.0625em) and (width <= 90em) {
  .large-only-text-left {
    text-align: left !important;
  }

  .large-only-text-right {
    text-align: right !important;
  }

  .large-only-text-center {
    text-align: center !important;
  }

  .large-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (width >= 64.0625em) {
  .large-text-left {
    text-align: left !important;
  }

  .large-text-right {
    text-align: right !important;
  }

  .large-text-center {
    text-align: center !important;
  }

  .large-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (width >= 90.0625em) and (width <= 120em) {
  .xlarge-only-text-left {
    text-align: left !important;
  }

  .xlarge-only-text-right {
    text-align: right !important;
  }

  .xlarge-only-text-center {
    text-align: center !important;
  }

  .xlarge-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (width >= 90.0625em) {
  .xlarge-text-left {
    text-align: left !important;
  }

  .xlarge-text-right {
    text-align: right !important;
  }

  .xlarge-text-center {
    text-align: center !important;
  }

  .xlarge-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (width >= 120.0625em) and (width <= 6249999.9375em) {
  .xxlarge-only-text-left {
    text-align: left !important;
  }

  .xxlarge-only-text-right {
    text-align: right !important;
  }

  .xxlarge-only-text-center {
    text-align: center !important;
  }

  .xxlarge-only-text-justify {
    text-align: justify !important;
  }
}

@media only screen and (width >= 120.0625em) {
  .xxlarge-text-left {
    text-align: left !important;
  }

  .xxlarge-text-right {
    text-align: right !important;
  }

  .xxlarge-text-center {
    text-align: center !important;
  }

  .xxlarge-text-justify {
    text-align: justify !important;
  }
}

/* Typography resets */
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}

/* Default Link Styles */
a {
  line-height: inherit;
  color: #2DB1FF;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #0078a0;
}

a img {
  border: none;
}

/* Default paragraph styles */
p {
  margin-bottom: 1.25rem;
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.6;
  text-rendering: optimizelegibility;
}

p.lead {
  font-size: 1.2188rem;
  line-height: 1.6;
}

p aside {
  font-size: 0.875rem;
  font-style: italic;
  line-height: 1.35;
}

/* Default header styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
  color: #222;
  text-rendering: optimizelegibility;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-size: 60%;
  line-height: 0;
  color: #6f6f6f;
}

h1 {
  font-size: 2.125rem;
}

h2 {
  font-size: 1.6875rem;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #6f6f6f;
}

hr {
  height: 0;
  margin: 1.25rem 0 1.1875rem;
  clear: both;
  border: solid #ddd;
  border-width: 1px 0 0;
}

/* Helpful Typography Defaults */
em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 60%;
  line-height: inherit;
}

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #333;
  background-color: #f8f8f8;
  border-color: #dfdfdf;
  border-style: solid;
  border-width: 1px;
}

/* Lists */
ul,
ol,
dl {
  margin-bottom: 1.25rem;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.6;
  list-style-position: outside;
}

ul {
  margin-left: 1.1rem;
}

/* Unordered Lists */
ul li ul,
ul li ol {
  margin-bottom: 0;
  margin-left: 1.25rem;
}

ul.square li ul,
ul.circle li ul,
ul.disc li ul {
  list-style: inherit;
}

ul.square {
  margin-left: 1.1rem;
  list-style-type: square;
}

ul.circle {
  margin-left: 1.1rem;
  list-style-type: circle;
}

ul.disc {
  margin-left: 1.1rem;
  list-style-type: disc;
}

/* Ordered Lists */
ol {
  margin-left: 1.4rem;
}

ol li ul,
ol li ol {
  margin-bottom: 0;
  margin-left: 1.25rem;
}

.no-bullet {
  margin-left: 0;
  list-style-type: none;
}

.no-bullet li ul,
.no-bullet li ol {
  margin-bottom: 0;
  margin-left: 1.25rem;
  list-style: none;
}

/* Definition Lists */
dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold;
}

dl dd {
  margin-bottom: 0.75rem;
}

/* Abbreviations */
abbr,
acronym {
  font-size: 90%;
  color: #222;
  text-transform: uppercase;
  cursor: help;
}

abbr {
  text-transform: none;
}

abbr[title] {
  border-bottom: 1px dotted #ddd;
}

/* Blockquotes */
blockquote {
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  margin: 0 0 1.25rem;
  border-left: 1px solid #ddd;
}

blockquote cite {
  display: block;
  font-size: 0.8125rem;
  color: #555;
}

blockquote cite::before {
  content: "\2014 \0020";
}

blockquote cite a,
blockquote cite a:visited {
  color: #555;
}

blockquote,
blockquote p {
  line-height: 1.6;
  color: #6f6f6f;
}

/* Microformats */
.vcard {
  display: inline-block;
  padding: 0.625rem 0.75rem;
  margin: 0 0 1.25rem;
  border: 1px solid #ddd;
}

.vcard li {
  display: block;
  margin: 0;
}

.vcard .fn {
  font-size: 0.9375rem;
  font-weight: bold;
}

.vevent .summary {
  font-weight: bold;
}

.vevent abbr {
  padding: 0 0.0625rem;
  font-weight: bold;
  text-decoration: none;
  cursor: default;
  border: none;
}

@media only screen and (width >= 40.0625em) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.4;
  }

  h1 {
    font-size: 2.75rem;
  }

  h2 {
    font-size: 2.3125rem;
  }

  h3 {
    font-size: 1.6875rem;
  }

  h4 {
    font-size: 1.4375rem;
  }

  h5 {
    font-size: 1.125rem;
  }

  h6 {
    font-size: 1rem;
  }
}

/*
 * Print styles.
 *
 * Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/
 * Credit to Paul Irish and HTML5 Boilerplate (html5boilerplate.com)
*/
@media print {
  * {
    color: #000 !important;
    text-shadow: none !important;
    background: transparent !important;

    /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  .ir a::after,
  a[href^="javascript:"]::after,
  a[href^="#"]::after {
    content: "";
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;

    /* h5bp.com/t */
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.34in;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

.reveal-modal-bg {
  position: fixed;
  inset: 0;
  z-index: 1004;
  display: none;
  background: #000;
  background: rgb(0 0 0 / 45%);
}

.reveal-modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1005;
  display: none;
  width: 100%;
  padding: 1.875rem;
  visibility: hidden;
  background-color: #fff;
  border: solid 1px #666;
  border-radius: 3px;
  box-shadow: 0 0 10px rgb(0 0 0 / 40%);
}

@media only screen and (width <= 40em) {
  .reveal-modal {
    min-height: 100vh;
  }
}

.reveal-modal .column,
.reveal-modal .columns {
  min-width: 0;
}

.reveal-modal > :first-child {
  margin-top: 0;
}

.reveal-modal > :last-child {
  margin-bottom: 0;
}

@media only screen and (width >= 40.0625em) {
  .reveal-modal {
    right: 0;
    left: 0;
    width: 80%;
    max-width: 62.5rem;
    margin: 0 auto;
  }
}

@media only screen and (width >= 40.0625em) {
  .reveal-modal {
    top: 6.25rem;
  }
}

.reveal-modal.radius {
  border-radius: 3px;
  box-shadow: none;
}

.reveal-modal.round {
  border-radius: 1000px;
  box-shadow: none;
}

.reveal-modal.collapse {
  padding: 0;
  box-shadow: none;
}

@media only screen and (width >= 40.0625em) {
  .reveal-modal.tiny {
    right: 0;
    left: 0;
    width: 30%;
    max-width: 62.5rem;
    margin: 0 auto;
  }
}

@media only screen and (width >= 40.0625em) {
  .reveal-modal.small {
    right: 0;
    left: 0;
    width: 40%;
    max-width: 62.5rem;
    margin: 0 auto;
  }
}

@media only screen and (width >= 40.0625em) {
  .reveal-modal.medium {
    right: 0;
    left: 0;
    width: 60%;
    max-width: 62.5rem;
    margin: 0 auto;
  }
}

@media only screen and (width >= 40.0625em) {
  .reveal-modal.large {
    right: 0;
    left: 0;
    width: 70%;
    max-width: 62.5rem;
    margin: 0 auto;
  }
}

@media only screen and (width >= 40.0625em) {
  .reveal-modal.xlarge {
    right: 0;
    left: 0;
    width: 95%;
    max-width: 62.5rem;
    margin: 0 auto;
  }
}

.reveal-modal.full {
  top: 0;
  left: 0;
  max-width: none !important;
  height: 100vh;
  height: 100%;
  min-height: 100vh;
  margin-left: 0 !important;
}

@media only screen and (width >= 40.0625em) {
  .reveal-modal.full {
    right: 0;
    left: 0;
    width: 100%;
    max-width: 62.5rem;
    margin: 0 auto;
  }
}

.reveal-modal.toback {
  z-index: 1003;
}

.reveal-modal .close-reveal-modal {
  position: absolute;
  top: 0.625rem;
  right: 1.375rem;
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1;
  color: #aaa;
  cursor: pointer;
}

/* Tooltips */
.has-tip {
  font-weight: bold;
  color: #333;
  cursor: help;
  border-bottom: dotted 1px #ccc;
}

.has-tip:hover,
.has-tip:focus {
  color: #2DB1FF;
  border-bottom: dotted 1px #003f54;
}

.has-tip.tip-left,
.has-tip.tip-right {
  float: none !important;
}

.tooltip {
  position: absolute;
  left: 50%;
  z-index: 1006;
  display: none;
  width: 100%;
  max-width: 300px;
  padding: 0.75rem;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.3;
  color: #fff;
  background: #333;
}

.tooltip > .nub {
  position: absolute;
  top: -10px;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  pointer-events: none;
  border: solid 5px;
  border-color: transparent transparent #333;
}

.tooltip > .nub.rtl {
  right: 5px;
  left: auto;
}

.tooltip.radius {
  border-radius: 3px;
}

.tooltip.round {
  border-radius: 1000px;
}

.tooltip.round > .nub {
  left: 2rem;
}

.tooltip.opened {
  color: #2DB1FF !important;
  border-bottom: dotted 1px #003f54 !important;
}

.tap-to-close {
  display: block;
  font-size: 0.625rem;
  font-weight: normal;
  color: #777;
}

@media only screen {
  .tooltip > .nub {
    top: -10px;
    border-color: transparent transparent #333;
  }

  .tooltip.tip-top > .nub {
    top: auto;
    bottom: -10px;
    border-color: #333 transparent transparent;
  }

  .tooltip.tip-left,
  .tooltip.tip-right {
    float: none !important;
  }

  .tooltip.tip-left > .nub {
    top: 50%;
    right: -10px;
    left: auto;
    margin-top: -5px;
    border-color: transparent transparent transparent #333;
  }

  .tooltip.tip-right > .nub {
    top: 50%;
    right: auto;
    left: -10px;
    margin-top: -5px;
    border-color: transparent #333 transparent transparent;
  }
}

/* Clearing Styles */
.clearing-thumbs,
[data-clearing] {
  margin-bottom: 0;
  margin-left: 0;
  list-style: none;
}

.clearing-thumbs::before,
.clearing-thumbs::after,
[data-clearing]::before,
[data-clearing]::after {
  display: table;
  content: " ";
}

.clearing-thumbs::after,
[data-clearing]::after {
  clear: both;
}

.clearing-thumbs li,
[data-clearing] li {
  float: left;
  margin-right: 10px;
}

.clearing-thumbs[class*="block-grid-"] li,
[data-clearing][class*="block-grid-"] li {
  margin-right: 0;
}

.clearing-blackout {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
  background: #333;
}

.clearing-blackout .clearing-close {
  display: block;
}

.clearing-container {
  position: relative;
  z-index: 998;
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.clearing-touch-label {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 0.6em;
  color: #aaa;
}

.visible-img {
  position: relative;
  height: 95%;
}

.visible-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100%;
  max-height: 100%;
  transform: translateY(-50%) translateX(-50%);
}

.clearing-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 30px 20px;
  margin-bottom: 0;
  font-size: 0.875em;
  line-height: 1.3;
  color: #ccc;
  text-align: center;
  background: #333;
}

.clearing-close {
  z-index: 999;
  display: none;
  padding-top: 10px;
  padding-left: 20px;
  font-size: 30px;
  line-height: 1;
  color: #ccc;
}

.clearing-close:hover,
.clearing-close:focus {
  color: #ccc;
}

.clearing-assembled .clearing-container {
  height: 100%;
}

.clearing-assembled .clearing-container .carousel > ul {
  display: none;
}

.clearing-feature li {
  display: none;
}

.clearing-feature li.clearing-featured-img {
  display: block;
}

@media only screen and (width >= 40.0625em) {
  .clearing-main-prev,
  .clearing-main-next {
    position: absolute;
    top: 0;
    width: 40px;
    height: 100%;
  }

  .clearing-main-prev > span,
  .clearing-main-next > span {
    position: absolute;
    top: 50%;
    display: block;
    width: 0;
    height: 0;
    border: solid 12px;
  }

  .clearing-main-prev > span:hover,
  .clearing-main-next > span:hover {
    opacity: 0.8;
  }

  .clearing-main-prev {
    left: 0;
  }

  .clearing-main-prev > span {
    left: 5px;
    border-color: transparent;
    border-right-color: #ccc;
  }

  .clearing-main-next {
    right: 0;
  }

  .clearing-main-next > span {
    border-color: transparent;
    border-left-color: #ccc;
  }

  .clearing-main-prev.disabled,
  .clearing-main-next.disabled {
    opacity: 0.3;
  }

  .clearing-assembled .clearing-container .carousel {
    height: 120px;
    margin-top: 10px;
    text-align: center;
    background: rgb(51 51 51 / 80%);
  }

  .clearing-assembled .clearing-container .carousel > ul {
    position: relative;
    z-index: 999;
    display: inline-block;
    float: none;
    height: 100%;
  }

  .clearing-assembled .clearing-container .carousel > ul li {
    position: relative;
    display: block;
    float: left;
    width: 120px;
    min-height: inherit;
    padding: 0;
    margin-right: 0;
    overflow: hidden;
    clear: none;
    cursor: pointer;
    opacity: 0.4;
  }

  .clearing-assembled .clearing-container .carousel > ul li.fix-height img {
    max-width: none;
    height: 100%;
  }

  .clearing-assembled .clearing-container .carousel > ul li a.th {
    display: block;
    border: none;
    box-shadow: none;
  }

  .clearing-assembled .clearing-container .carousel > ul li img {
    width: 100% !important;
    cursor: pointer !important;
  }

  .clearing-assembled .clearing-container .carousel > ul li.visible {
    opacity: 1;
  }

  .clearing-assembled .clearing-container .carousel > ul li:hover {
    opacity: 0.8;
  }

  .clearing-assembled .clearing-container .visible-img {
    height: 85%;
    overflow: hidden;
    background: #333;
  }

  .clearing-close {
    position: absolute;
    top: 10px;
    right: 20px;
    padding-top: 0;
    padding-left: 0;
  }
}

.label {
  position: relative;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  margin-bottom: auto;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 0.6875rem;
  font-weight: normal;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background-color: #2DB1FF;
}

.label.radius {
  border-radius: 3px;
}

.label.round {
  border-radius: 1000px;
}

.label.alert {
  color: #fff;
  background-color: #fa5252;
}

.label.warning {
  color: #fff;
  background-color: #fd7e14;
}

.label.success {
  color: #fff;
  background-color: #12b886;
}

.label.secondary {
  color: #333;
  background-color: #e7e7e7;
}

.label.info {
  color: #333;
  background-color: #a0d3e8;
}

.tab-bar {
  position: relative;
  height: 2.8125rem;
  line-height: 2.8125rem;
  color: #fff;
  background: #333;
  backface-visibility: hidden;
}

.tab-bar h1,
.tab-bar h2,
.tab-bar h3,
.tab-bar h4,
.tab-bar h5,
.tab-bar h6 {
  margin: 0;
  font-weight: bold;
  line-height: 2.8125rem;
  color: #fff;
}

.tab-bar h1,
.tab-bar h2,
.tab-bar h3,
.tab-bar h4 {
  font-size: 1.125rem;
}

.left-small {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.8125rem;
  height: 2.8125rem;
  border-right: solid 1px #1a1a1a;
}

.right-small {
  position: absolute;
  top: 0;
  right: 0;
  width: 2.8125rem;
  height: 2.8125rem;
  border-left: solid 1px #1a1a1a;
}

.tab-bar-section {
  position: absolute;
  top: 0;
  height: 2.8125rem;
  padding: 0 0.625rem;
  text-align: center;
}

.tab-bar-section.left {
  right: 2.8125rem;
  left: 0;
  text-align: left;
}

.tab-bar-section.right {
  right: 0;
  left: 2.8125rem;
  text-align: right;
}

.tab-bar-section.middle {
  right: 2.8125rem;
  left: 2.8125rem;
}

.tab-bar .menu-icon {
  position: relative;
  display: block;
  width: 2.8125rem;
  height: 2.8125rem;
  padding: 0;
  color: #fff;
  text-indent: 2.1875rem;
  transform: translate3d(0, 0, 0);
}

.tab-bar .menu-icon span::after {
  position: absolute;
  top: 50%;
  left: 0.9063rem;
  display: block;
  width: 1rem;
  height: 0;
  margin-top: -0.5rem;
  content: "";
  box-shadow: 0 0 0 1px #fff, 0 7px 0 1px #fff, 0 14px 0 1px #fff;
}

.tab-bar .menu-icon span:hover::after {
  box-shadow: 0 0 0 1px #b3b3b3, 0 7px 0 1px #b3b3b3, 0 14px 0 1px #b3b3b3;
}

/* Foundation Dropdowns */
.f-dropdown {
  position: absolute;
  left: -9999px;
  z-index: 89;
  display: none;
  width: 100%;
  max-width: 200px;
  height: auto;
  max-height: none;
  margin-top: 2px;
  margin-left: 0;
  font-size: 0.875rem;
  list-style: none;
  background: #fff;
  border: solid 1px #ccc;
}

.f-dropdown.open {
  display: block;
}

.f-dropdown > *:first-child {
  margin-top: 0;
}

.f-dropdown > *:last-child {
  margin-bottom: 0;
}

.f-dropdown::before {
  position: absolute;
  top: -12px;
  left: 10px;
  z-index: 89;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border: 6px inset transparent;
  border-bottom: solid #fff;
}

.f-dropdown::after {
  position: absolute;
  top: -14px;
  left: 9px;
  z-index: 88;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border: 7px inset transparent;
  border-bottom: solid #ccc;
}

.f-dropdown.right::before {
  right: 10px;
  left: auto;
}

.f-dropdown.right::after {
  right: 9px;
  left: auto;
}

.f-dropdown.drop-right {
  position: absolute;
  left: -9999px;
  z-index: 89;
  display: none;
  width: 100%;
  max-width: 200px;
  height: auto;
  max-height: none;
  margin-top: 0;
  margin-left: 2px;
  font-size: 0.875rem;
  list-style: none;
  background: #fff;
  border: solid 1px #ccc;
}

.f-dropdown.drop-right.open {
  display: block;
}

.f-dropdown.drop-right > *:first-child {
  margin-top: 0;
}

.f-dropdown.drop-right > *:last-child {
  margin-bottom: 0;
}

.f-dropdown.drop-right::before {
  position: absolute;
  top: 10px;
  left: -12px;
  z-index: 89;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border: 6px inset transparent;
  border-right: solid #fff;
}

.f-dropdown.drop-right::after {
  position: absolute;
  top: 9px;
  left: -14px;
  z-index: 88;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border: 7px inset transparent;
  border-right: solid #ccc;
}

.f-dropdown.drop-left {
  position: absolute;
  left: -9999px;
  z-index: 89;
  display: none;
  width: 100%;
  max-width: 200px;
  height: auto;
  max-height: none;
  margin-top: 0;
  margin-left: -2px;
  font-size: 0.875rem;
  list-style: none;
  background: #fff;
  border: solid 1px #ccc;
}

.f-dropdown.drop-left.open {
  display: block;
}

.f-dropdown.drop-left > *:first-child {
  margin-top: 0;
}

.f-dropdown.drop-left > *:last-child {
  margin-bottom: 0;
}

.f-dropdown.drop-left::before {
  position: absolute;
  top: 10px;
  right: -12px;
  left: auto;
  z-index: 89;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border: 6px inset transparent;
  border-left: solid #fff;
}

.f-dropdown.drop-left::after {
  position: absolute;
  top: 9px;
  right: -14px;
  left: auto;
  z-index: 88;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border: 7px inset transparent;
  border-left: solid #ccc;
}

.f-dropdown.drop-top {
  position: absolute;
  left: -9999px;
  z-index: 89;
  display: none;
  width: 100%;
  max-width: 200px;
  height: auto;
  max-height: none;
  margin-top: -2px;
  margin-left: 0;
  font-size: 0.875rem;
  list-style: none;
  background: #fff;
  border: solid 1px #ccc;
}

.f-dropdown.drop-top.open {
  display: block;
}

.f-dropdown.drop-top > *:first-child {
  margin-top: 0;
}

.f-dropdown.drop-top > *:last-child {
  margin-bottom: 0;
}

.f-dropdown.drop-top::before {
  position: absolute;
  inset: auto auto -12px 10px;
  z-index: 89;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border: 6px inset transparent;
  border-top: solid #fff;
}

.f-dropdown.drop-top::after {
  position: absolute;
  inset: auto auto -14px 9px;
  z-index: 88;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border: 7px inset transparent;
  border-top: solid #ccc;
}

.f-dropdown li {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.125rem;
  cursor: pointer;
}

.f-dropdown li:hover,
.f-dropdown li:focus {
  background: #eee;
}

.f-dropdown li a {
  display: block;
  padding: 0.5rem;
  color: #555;
}

.f-dropdown.content {
  position: absolute;
  left: -9999px;
  z-index: 89;
  display: none;
  width: 100%;
  max-width: 200px;
  height: auto;
  max-height: none;
  padding: 1.25rem;
  margin-left: 0;
  font-size: 0.875rem;
  list-style: none;
  background: #fff;
  border: solid 1px #ccc;
}

.f-dropdown.content.open {
  display: block;
}

.f-dropdown.content > *:first-child {
  margin-top: 0;
}

.f-dropdown.content > *:last-child {
  margin-bottom: 0;
}

.f-dropdown.radius {
  border-radius: 3px;
}

.f-dropdown.tiny {
  max-width: 200px;
}

.f-dropdown.small {
  max-width: 300px;
}

.f-dropdown.medium {
  max-width: 500px;
}

.f-dropdown.large {
  max-width: 800px;
}

.f-dropdown.mega {
  width: 100% !important;
  max-width: 100% !important;
}

.f-dropdown.mega.open {
  left: 0 !important;
}

table {
  margin-bottom: 1.25rem;
  table-layout: auto;
  background: #fff;
  border: solid 1px #ddd;
}

table thead {
  background: #f5f5f5;
}

table thead tr th,
table thead tr td {
  padding: 0.5rem 0.625rem 0.625rem;
  font-size: 0.875rem;
  font-weight: bold;
  color: #222;
}

table tfoot {
  background: #f5f5f5;
}

table tfoot tr th,
table tfoot tr td {
  padding: 0.5rem 0.625rem 0.625rem;
  font-size: 0.875rem;
  font-weight: bold;
  color: #222;
}

table tr th,
table tr td {
  padding: 0.5625rem 0.625rem;
  font-size: 0.875rem;
  color: #222;
  text-align: left;
}

table tr.even,
table tr.alt,
table tr:nth-of-type(even) {
  background: #f9f9f9;
}

table thead tr th,
table tfoot tr th,
table tfoot tr td,
table tbody tr th,
table tbody tr td,
table tr td {
  display: table-cell;
  line-height: 1.125rem;
}

[class*="block-grid-"] {
  display: block;
  padding: 0;
  margin: 0 -0.625rem;
}

[class*="block-grid-"]::before,
[class*="block-grid-"]::after {
  display: table;
  content: " ";
}

[class*="block-grid-"]::after {
  clear: both;
}

[class*="block-grid-"] > li {
  display: block;
  float: left;
  height: auto;
  padding: 0 0.625rem 1.25rem;
}

@media only screen {
  .small-block-grid-1 > li {
    width: 100%;
    list-style: none;
  }

  .small-block-grid-1 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }

  .small-block-grid-2 > li {
    width: 50%;
    list-style: none;
  }

  .small-block-grid-2 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }

  .small-block-grid-3 > li {
    width: 33.3333%;
    list-style: none;
  }

  .small-block-grid-3 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }

  .small-block-grid-4 > li {
    width: 25%;
    list-style: none;
  }

  .small-block-grid-4 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }

  .small-block-grid-5 > li {
    width: 20%;
    list-style: none;
  }

  .small-block-grid-5 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }

  .small-block-grid-6 > li {
    width: 16.6667%;
    list-style: none;
  }

  .small-block-grid-6 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }

  .small-block-grid-7 > li {
    width: 14.2857%;
    list-style: none;
  }

  .small-block-grid-7 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }

  .small-block-grid-8 > li {
    width: 12.5%;
    list-style: none;
  }

  .small-block-grid-8 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }

  .small-block-grid-9 > li {
    width: 11.1111%;
    list-style: none;
  }

  .small-block-grid-9 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }

  .small-block-grid-10 > li {
    width: 10%;
    list-style: none;
  }

  .small-block-grid-10 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }

  .small-block-grid-11 > li {
    width: 9.0909%;
    list-style: none;
  }

  .small-block-grid-11 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }

  .small-block-grid-12 > li {
    width: 8.3333%;
    list-style: none;
  }

  .small-block-grid-12 > li:nth-of-type(1n) {
    clear: none;
  }

  .small-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }
}

@media only screen and (width >= 40.0625em) {
  .medium-block-grid-1 > li {
    width: 100%;
    list-style: none;
  }

  .medium-block-grid-1 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }

  .medium-block-grid-2 > li {
    width: 50%;
    list-style: none;
  }

  .medium-block-grid-2 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }

  .medium-block-grid-3 > li {
    width: 33.3333%;
    list-style: none;
  }

  .medium-block-grid-3 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }

  .medium-block-grid-4 > li {
    width: 25%;
    list-style: none;
  }

  .medium-block-grid-4 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }

  .medium-block-grid-5 > li {
    width: 20%;
    list-style: none;
  }

  .medium-block-grid-5 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }

  .medium-block-grid-6 > li {
    width: 16.6667%;
    list-style: none;
  }

  .medium-block-grid-6 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }

  .medium-block-grid-7 > li {
    width: 14.2857%;
    list-style: none;
  }

  .medium-block-grid-7 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }

  .medium-block-grid-8 > li {
    width: 12.5%;
    list-style: none;
  }

  .medium-block-grid-8 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }

  .medium-block-grid-9 > li {
    width: 11.1111%;
    list-style: none;
  }

  .medium-block-grid-9 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }

  .medium-block-grid-10 > li {
    width: 10%;
    list-style: none;
  }

  .medium-block-grid-10 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }

  .medium-block-grid-11 > li {
    width: 9.0909%;
    list-style: none;
  }

  .medium-block-grid-11 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }

  .medium-block-grid-12 > li {
    width: 8.3333%;
    list-style: none;
  }

  .medium-block-grid-12 > li:nth-of-type(1n) {
    clear: none;
  }

  .medium-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }
}

@media only screen and (width >= 64.0625em) {
  .large-block-grid-1 > li {
    width: 100%;
    list-style: none;
  }

  .large-block-grid-1 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-1 > li:nth-of-type(1n+1) {
    clear: both;
  }

  .large-block-grid-2 > li {
    width: 50%;
    list-style: none;
  }

  .large-block-grid-2 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-2 > li:nth-of-type(2n+1) {
    clear: both;
  }

  .large-block-grid-3 > li {
    width: 33.3333%;
    list-style: none;
  }

  .large-block-grid-3 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-3 > li:nth-of-type(3n+1) {
    clear: both;
  }

  .large-block-grid-4 > li {
    width: 25%;
    list-style: none;
  }

  .large-block-grid-4 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-4 > li:nth-of-type(4n+1) {
    clear: both;
  }

  .large-block-grid-5 > li {
    width: 20%;
    list-style: none;
  }

  .large-block-grid-5 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-5 > li:nth-of-type(5n+1) {
    clear: both;
  }

  .large-block-grid-6 > li {
    width: 16.6667%;
    list-style: none;
  }

  .large-block-grid-6 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-6 > li:nth-of-type(6n+1) {
    clear: both;
  }

  .large-block-grid-7 > li {
    width: 14.2857%;
    list-style: none;
  }

  .large-block-grid-7 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-7 > li:nth-of-type(7n+1) {
    clear: both;
  }

  .large-block-grid-8 > li {
    width: 12.5%;
    list-style: none;
  }

  .large-block-grid-8 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-8 > li:nth-of-type(8n+1) {
    clear: both;
  }

  .large-block-grid-9 > li {
    width: 11.1111%;
    list-style: none;
  }

  .large-block-grid-9 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-9 > li:nth-of-type(9n+1) {
    clear: both;
  }

  .large-block-grid-10 > li {
    width: 10%;
    list-style: none;
  }

  .large-block-grid-10 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-10 > li:nth-of-type(10n+1) {
    clear: both;
  }

  .large-block-grid-11 > li {
    width: 9.0909%;
    list-style: none;
  }

  .large-block-grid-11 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-11 > li:nth-of-type(11n+1) {
    clear: both;
  }

  .large-block-grid-12 > li {
    width: 8.3333%;
    list-style: none;
  }

  .large-block-grid-12 > li:nth-of-type(1n) {
    clear: none;
  }

  .large-block-grid-12 > li:nth-of-type(12n+1) {
    clear: both;
  }
}

.flex-video {
  position: relative;
  height: 0;
  padding-top: 1.5625rem;
  padding-bottom: 67.5%;
  margin-bottom: 1rem;
  overflow: hidden;
}

.flex-video.widescreen {
  padding-bottom: 56.34%;
}

.flex-video.vimeo {
  padding-top: 0;
}

.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.switch {
  position: relative;
  padding: 0;
  margin-bottom: 1.5rem;
  user-select: none;
  border: none;
  outline: 0;
}

.switch label {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  margin-bottom: 1rem;
  color: transparent;
  text-indent: 100%;
  cursor: pointer;
  background: #ddd;
  transition: left 0.15s ease-out;
}

.switch input {
  position: absolute;
  top: 9px;
  left: 10px;
  padding: 0;
  opacity: 0;
}

.switch input + label {
  margin-right: 0;
  margin-left: 0;
}

.switch label::after {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  content: "";
  background: #fff;
  transition: left 0.15s ease-out;
  transform: translate3d(0, 0, 0);
}

.switch input:checked + label {
  background: #2DB1FF;
}

.switch input:checked + label::after {
  left: 2.25rem;
}

.switch.large label {
  width: 5rem;
  height: 2.5rem;
}

.switch.large label::after {
  width: 2rem;
  height: 2rem;
}

.switch.large input:checked + label::after {
  left: 2.75rem;
}

.switch.small label {
  width: 3.5rem;
  height: 1.75rem;
}

.switch.small label::after {
  width: 1.25rem;
  height: 1.25rem;
}

.switch.small input:checked + label::after {
  left: 2rem;
}

.switch.tiny label {
  width: 3rem;
  height: 1.5rem;
}

.switch.tiny label::after {
  width: 1rem;
  height: 1rem;
}

.switch.tiny input:checked + label::after {
  left: 1.75rem;
}

.switch.radius label {
  border-radius: 4px;
}

.switch.radius label::after {
  border-radius: 3px;
}

.switch.round {
  border-radius: 1000px;
}

.switch.round label {
  border-radius: 2rem;
}

.switch.round label::after {
  border-radius: 2rem;
}

/* small displays */
@media only screen {
  .show-for-small-only,
  .show-for-small-up,
  .show-for-small,
  .show-for-small-down,
  .hide-for-medium-only,
  .hide-for-medium-up,
  .hide-for-medium,
  .show-for-medium-down,
  .hide-for-large-only,
  .hide-for-large-up,
  .hide-for-large,
  .show-for-large-down,
  .hide-for-xlarge-only,
  .hide-for-xlarge-up,
  .hide-for-xlarge,
  .show-for-xlarge-down,
  .hide-for-xxlarge-only,
  .hide-for-xxlarge-up,
  .hide-for-xxlarge,
  .show-for-xxlarge-down {
    display: inherit !important;
  }

  .hide-for-small-only,
  .hide-for-small-up,
  .hide-for-small,
  .hide-for-small-down,
  .show-for-medium-only,
  .show-for-medium-up,
  .show-for-medium,
  .hide-for-medium-down,
  .show-for-large-only,
  .show-for-large-up,
  .show-for-large,
  .hide-for-large-down,
  .show-for-xlarge-only,
  .show-for-xlarge-up,
  .show-for-xlarge,
  .hide-for-xlarge-down,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up,
  .show-for-xxlarge,
  .hide-for-xxlarge-down {
    display: none !important;
  }

  .visible-for-small-only,
  .visible-for-small-up,
  .visible-for-small,
  .visible-for-small-down,
  .hidden-for-medium-only,
  .hidden-for-medium-up,
  .hidden-for-medium,
  .visible-for-medium-down,
  .hidden-for-large-only,
  .hidden-for-large-up,
  .hidden-for-large,
  .visible-for-large-down,
  .hidden-for-xlarge-only,
  .hidden-for-xlarge-up,
  .hidden-for-xlarge,
  .visible-for-xlarge-down,
  .hidden-for-xxlarge-only,
  .hidden-for-xxlarge-up,
  .hidden-for-xxlarge,
  .visible-for-xxlarge-down {
    position: static !important;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
  }

  .hidden-for-small-only,
  .hidden-for-small-up,
  .hidden-for-small,
  .hidden-for-small-down,
  .visible-for-medium-only,
  .visible-for-medium-up,
  .visible-for-medium,
  .hidden-for-medium-down,
  .visible-for-large-only,
  .visible-for-large-up,
  .visible-for-large,
  .hidden-for-large-down,
  .visible-for-xlarge-only,
  .visible-for-xlarge-up,
  .visible-for-xlarge,
  .hidden-for-xlarge-down,
  .visible-for-xxlarge-only,
  .visible-for-xxlarge-up,
  .visible-for-xxlarge,
  .hidden-for-xxlarge-down {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  table.show-for-small-only,
  table.show-for-small-up,
  table.show-for-small,
  table.show-for-small-down,
  table.hide-for-medium-only,
  table.hide-for-medium-up,
  table.hide-for-medium,
  table.show-for-medium-down,
  table.hide-for-large-only,
  table.hide-for-large-up,
  table.hide-for-large,
  table.show-for-large-down,
  table.hide-for-xlarge-only,
  table.hide-for-xlarge-up,
  table.hide-for-xlarge,
  table.show-for-xlarge-down,
  table.hide-for-xxlarge-only,
  table.hide-for-xxlarge-up,
  table.hide-for-xxlarge,
  table.show-for-xxlarge-down {
    display: table !important;
  }

  thead.show-for-small-only,
  thead.show-for-small-up,
  thead.show-for-small,
  thead.show-for-small-down,
  thead.hide-for-medium-only,
  thead.hide-for-medium-up,
  thead.hide-for-medium,
  thead.show-for-medium-down,
  thead.hide-for-large-only,
  thead.hide-for-large-up,
  thead.hide-for-large,
  thead.show-for-large-down,
  thead.hide-for-xlarge-only,
  thead.hide-for-xlarge-up,
  thead.hide-for-xlarge,
  thead.show-for-xlarge-down,
  thead.hide-for-xxlarge-only,
  thead.hide-for-xxlarge-up,
  thead.hide-for-xxlarge,
  thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }

  tbody.show-for-small-only,
  tbody.show-for-small-up,
  tbody.show-for-small,
  tbody.show-for-small-down,
  tbody.hide-for-medium-only,
  tbody.hide-for-medium-up,
  tbody.hide-for-medium,
  tbody.show-for-medium-down,
  tbody.hide-for-large-only,
  tbody.hide-for-large-up,
  tbody.hide-for-large,
  tbody.show-for-large-down,
  tbody.hide-for-xlarge-only,
  tbody.hide-for-xlarge-up,
  tbody.hide-for-xlarge,
  tbody.show-for-xlarge-down,
  tbody.hide-for-xxlarge-only,
  tbody.hide-for-xxlarge-up,
  tbody.hide-for-xxlarge,
  tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }

  tr.show-for-small-only,
  tr.show-for-small-up,
  tr.show-for-small,
  tr.show-for-small-down,
  tr.hide-for-medium-only,
  tr.hide-for-medium-up,
  tr.hide-for-medium,
  tr.show-for-medium-down,
  tr.hide-for-large-only,
  tr.hide-for-large-up,
  tr.hide-for-large,
  tr.show-for-large-down,
  tr.hide-for-xlarge-only,
  tr.hide-for-xlarge-up,
  tr.hide-for-xlarge,
  tr.show-for-xlarge-down,
  tr.hide-for-xxlarge-only,
  tr.hide-for-xxlarge-up,
  tr.hide-for-xxlarge,
  tr.show-for-xxlarge-down {
    display: table-row;
  }

  th.show-for-small-only,
  td.show-for-small-only,
  th.show-for-small-up,
  td.show-for-small-up,
  th.show-for-small,
  td.show-for-small,
  th.show-for-small-down,
  td.show-for-small-down,
  th.hide-for-medium-only,
  td.hide-for-medium-only,
  th.hide-for-medium-up,
  td.hide-for-medium-up,
  th.hide-for-medium,
  td.hide-for-medium,
  th.show-for-medium-down,
  td.show-for-medium-down,
  th.hide-for-large-only,
  td.hide-for-large-only,
  th.hide-for-large-up,
  td.hide-for-large-up,
  th.hide-for-large,
  td.hide-for-large,
  th.show-for-large-down,
  td.show-for-large-down,
  th.hide-for-xlarge-only,
  td.hide-for-xlarge-only,
  th.hide-for-xlarge-up,
  td.hide-for-xlarge-up,
  th.hide-for-xlarge,
  td.hide-for-xlarge,
  th.show-for-xlarge-down,
  td.show-for-xlarge-down,
  th.hide-for-xxlarge-only,
  td.hide-for-xxlarge-only,
  th.hide-for-xxlarge-up,
  td.hide-for-xxlarge-up,
  th.hide-for-xxlarge,
  td.hide-for-xxlarge,
  th.show-for-xxlarge-down,
  td.show-for-xxlarge-down {
    display: table-cell !important;
  }
}

/* medium displays */
@media only screen and (width >= 40.0625em) {
  .hide-for-small-only,
  .show-for-small-up,
  .hide-for-small,
  .hide-for-small-down,
  .show-for-medium-only,
  .show-for-medium-up,
  .show-for-medium,
  .show-for-medium-down,
  .hide-for-large-only,
  .hide-for-large-up,
  .hide-for-large,
  .show-for-large-down,
  .hide-for-xlarge-only,
  .hide-for-xlarge-up,
  .hide-for-xlarge,
  .show-for-xlarge-down,
  .hide-for-xxlarge-only,
  .hide-for-xxlarge-up,
  .hide-for-xxlarge,
  .show-for-xxlarge-down {
    display: inherit !important;
  }

  .show-for-small-only,
  .hide-for-small-up,
  .show-for-small,
  .show-for-small-down,
  .hide-for-medium-only,
  .hide-for-medium-up,
  .hide-for-medium,
  .hide-for-medium-down,
  .show-for-large-only,
  .show-for-large-up,
  .show-for-large,
  .hide-for-large-down,
  .show-for-xlarge-only,
  .show-for-xlarge-up,
  .show-for-xlarge,
  .hide-for-xlarge-down,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up,
  .show-for-xxlarge,
  .hide-for-xxlarge-down {
    display: none !important;
  }

  .hidden-for-small-only,
  .visible-for-small-up,
  .hidden-for-small,
  .hidden-for-small-down,
  .visible-for-medium-only,
  .visible-for-medium-up,
  .visible-for-medium,
  .visible-for-medium-down,
  .hidden-for-large-only,
  .hidden-for-large-up,
  .hidden-for-large,
  .visible-for-large-down,
  .hidden-for-xlarge-only,
  .hidden-for-xlarge-up,
  .hidden-for-xlarge,
  .visible-for-xlarge-down,
  .hidden-for-xxlarge-only,
  .hidden-for-xxlarge-up,
  .hidden-for-xxlarge,
  .visible-for-xxlarge-down {
    position: static !important;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
  }

  .visible-for-small-only,
  .hidden-for-small-up,
  .visible-for-small,
  .visible-for-small-down,
  .hidden-for-medium-only,
  .hidden-for-medium-up,
  .hidden-for-medium,
  .hidden-for-medium-down,
  .visible-for-large-only,
  .visible-for-large-up,
  .visible-for-large,
  .hidden-for-large-down,
  .visible-for-xlarge-only,
  .visible-for-xlarge-up,
  .visible-for-xlarge,
  .hidden-for-xlarge-down,
  .visible-for-xxlarge-only,
  .visible-for-xxlarge-up,
  .visible-for-xxlarge,
  .hidden-for-xxlarge-down {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  table.hide-for-small-only,
  table.show-for-small-up,
  table.hide-for-small,
  table.hide-for-small-down,
  table.show-for-medium-only,
  table.show-for-medium-up,
  table.show-for-medium,
  table.show-for-medium-down,
  table.hide-for-large-only,
  table.hide-for-large-up,
  table.hide-for-large,
  table.show-for-large-down,
  table.hide-for-xlarge-only,
  table.hide-for-xlarge-up,
  table.hide-for-xlarge,
  table.show-for-xlarge-down,
  table.hide-for-xxlarge-only,
  table.hide-for-xxlarge-up,
  table.hide-for-xxlarge,
  table.show-for-xxlarge-down {
    display: table !important;
  }

  thead.hide-for-small-only,
  thead.show-for-small-up,
  thead.hide-for-small,
  thead.hide-for-small-down,
  thead.show-for-medium-only,
  thead.show-for-medium-up,
  thead.show-for-medium,
  thead.show-for-medium-down,
  thead.hide-for-large-only,
  thead.hide-for-large-up,
  thead.hide-for-large,
  thead.show-for-large-down,
  thead.hide-for-xlarge-only,
  thead.hide-for-xlarge-up,
  thead.hide-for-xlarge,
  thead.show-for-xlarge-down,
  thead.hide-for-xxlarge-only,
  thead.hide-for-xxlarge-up,
  thead.hide-for-xxlarge,
  thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }

  tbody.hide-for-small-only,
  tbody.show-for-small-up,
  tbody.hide-for-small,
  tbody.hide-for-small-down,
  tbody.show-for-medium-only,
  tbody.show-for-medium-up,
  tbody.show-for-medium,
  tbody.show-for-medium-down,
  tbody.hide-for-large-only,
  tbody.hide-for-large-up,
  tbody.hide-for-large,
  tbody.show-for-large-down,
  tbody.hide-for-xlarge-only,
  tbody.hide-for-xlarge-up,
  tbody.hide-for-xlarge,
  tbody.show-for-xlarge-down,
  tbody.hide-for-xxlarge-only,
  tbody.hide-for-xxlarge-up,
  tbody.hide-for-xxlarge,
  tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }

  tr.hide-for-small-only,
  tr.show-for-small-up,
  tr.hide-for-small,
  tr.hide-for-small-down,
  tr.show-for-medium-only,
  tr.show-for-medium-up,
  tr.show-for-medium,
  tr.show-for-medium-down,
  tr.hide-for-large-only,
  tr.hide-for-large-up,
  tr.hide-for-large,
  tr.show-for-large-down,
  tr.hide-for-xlarge-only,
  tr.hide-for-xlarge-up,
  tr.hide-for-xlarge,
  tr.show-for-xlarge-down,
  tr.hide-for-xxlarge-only,
  tr.hide-for-xxlarge-up,
  tr.hide-for-xxlarge,
  tr.show-for-xxlarge-down {
    display: table-row;
  }

  th.hide-for-small-only,
  td.hide-for-small-only,
  th.show-for-small-up,
  td.show-for-small-up,
  th.hide-for-small,
  td.hide-for-small,
  th.hide-for-small-down,
  td.hide-for-small-down,
  th.show-for-medium-only,
  td.show-for-medium-only,
  th.show-for-medium-up,
  td.show-for-medium-up,
  th.show-for-medium,
  td.show-for-medium,
  th.show-for-medium-down,
  td.show-for-medium-down,
  th.hide-for-large-only,
  td.hide-for-large-only,
  th.hide-for-large-up,
  td.hide-for-large-up,
  th.hide-for-large,
  td.hide-for-large,
  th.show-for-large-down,
  td.show-for-large-down,
  th.hide-for-xlarge-only,
  td.hide-for-xlarge-only,
  th.hide-for-xlarge-up,
  td.hide-for-xlarge-up,
  th.hide-for-xlarge,
  td.hide-for-xlarge,
  th.show-for-xlarge-down,
  td.show-for-xlarge-down,
  th.hide-for-xxlarge-only,
  td.hide-for-xxlarge-only,
  th.hide-for-xxlarge-up,
  td.hide-for-xxlarge-up,
  th.hide-for-xxlarge,
  td.hide-for-xxlarge,
  th.show-for-xxlarge-down,
  td.show-for-xxlarge-down {
    display: table-cell !important;
  }
}

/* large displays */
@media only screen and (width >= 64.0625em) {
  .hide-for-small-only,
  .show-for-small-up,
  .hide-for-small,
  .hide-for-small-down,
  .hide-for-medium-only,
  .show-for-medium-up,
  .hide-for-medium,
  .hide-for-medium-down,
  .show-for-large-only,
  .show-for-large-up,
  .show-for-large,
  .show-for-large-down,
  .hide-for-xlarge-only,
  .hide-for-xlarge-up,
  .hide-for-xlarge,
  .show-for-xlarge-down,
  .hide-for-xxlarge-only,
  .hide-for-xxlarge-up,
  .hide-for-xxlarge,
  .show-for-xxlarge-down {
    display: inherit !important;
  }

  .show-for-small-only,
  .hide-for-small-up,
  .show-for-small,
  .show-for-small-down,
  .show-for-medium-only,
  .hide-for-medium-up,
  .show-for-medium,
  .show-for-medium-down,
  .hide-for-large-only,
  .hide-for-large-up,
  .hide-for-large,
  .hide-for-large-down,
  .show-for-xlarge-only,
  .show-for-xlarge-up,
  .show-for-xlarge,
  .hide-for-xlarge-down,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up,
  .show-for-xxlarge,
  .hide-for-xxlarge-down {
    display: none !important;
  }

  .hidden-for-small-only,
  .visible-for-small-up,
  .hidden-for-small,
  .hidden-for-small-down,
  .hidden-for-medium-only,
  .visible-for-medium-up,
  .hidden-for-medium,
  .hidden-for-medium-down,
  .visible-for-large-only,
  .visible-for-large-up,
  .visible-for-large,
  .visible-for-large-down,
  .hidden-for-xlarge-only,
  .hidden-for-xlarge-up,
  .hidden-for-xlarge,
  .visible-for-xlarge-down,
  .hidden-for-xxlarge-only,
  .hidden-for-xxlarge-up,
  .hidden-for-xxlarge,
  .visible-for-xxlarge-down {
    position: static !important;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
  }

  .visible-for-small-only,
  .hidden-for-small-up,
  .visible-for-small,
  .visible-for-small-down,
  .visible-for-medium-only,
  .hidden-for-medium-up,
  .visible-for-medium,
  .visible-for-medium-down,
  .hidden-for-large-only,
  .hidden-for-large-up,
  .hidden-for-large,
  .hidden-for-large-down,
  .visible-for-xlarge-only,
  .visible-for-xlarge-up,
  .visible-for-xlarge,
  .hidden-for-xlarge-down,
  .visible-for-xxlarge-only,
  .visible-for-xxlarge-up,
  .visible-for-xxlarge,
  .hidden-for-xxlarge-down {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  table.hide-for-small-only,
  table.show-for-small-up,
  table.hide-for-small,
  table.hide-for-small-down,
  table.hide-for-medium-only,
  table.show-for-medium-up,
  table.hide-for-medium,
  table.hide-for-medium-down,
  table.show-for-large-only,
  table.show-for-large-up,
  table.show-for-large,
  table.show-for-large-down,
  table.hide-for-xlarge-only,
  table.hide-for-xlarge-up,
  table.hide-for-xlarge,
  table.show-for-xlarge-down,
  table.hide-for-xxlarge-only,
  table.hide-for-xxlarge-up,
  table.hide-for-xxlarge,
  table.show-for-xxlarge-down {
    display: table !important;
  }

  thead.hide-for-small-only,
  thead.show-for-small-up,
  thead.hide-for-small,
  thead.hide-for-small-down,
  thead.hide-for-medium-only,
  thead.show-for-medium-up,
  thead.hide-for-medium,
  thead.hide-for-medium-down,
  thead.show-for-large-only,
  thead.show-for-large-up,
  thead.show-for-large,
  thead.show-for-large-down,
  thead.hide-for-xlarge-only,
  thead.hide-for-xlarge-up,
  thead.hide-for-xlarge,
  thead.show-for-xlarge-down,
  thead.hide-for-xxlarge-only,
  thead.hide-for-xxlarge-up,
  thead.hide-for-xxlarge,
  thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }

  tbody.hide-for-small-only,
  tbody.show-for-small-up,
  tbody.hide-for-small,
  tbody.hide-for-small-down,
  tbody.hide-for-medium-only,
  tbody.show-for-medium-up,
  tbody.hide-for-medium,
  tbody.hide-for-medium-down,
  tbody.show-for-large-only,
  tbody.show-for-large-up,
  tbody.show-for-large,
  tbody.show-for-large-down,
  tbody.hide-for-xlarge-only,
  tbody.hide-for-xlarge-up,
  tbody.hide-for-xlarge,
  tbody.show-for-xlarge-down,
  tbody.hide-for-xxlarge-only,
  tbody.hide-for-xxlarge-up,
  tbody.hide-for-xxlarge,
  tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }

  tr.hide-for-small-only,
  tr.show-for-small-up,
  tr.hide-for-small,
  tr.hide-for-small-down,
  tr.hide-for-medium-only,
  tr.show-for-medium-up,
  tr.hide-for-medium,
  tr.hide-for-medium-down,
  tr.show-for-large-only,
  tr.show-for-large-up,
  tr.show-for-large,
  tr.show-for-large-down,
  tr.hide-for-xlarge-only,
  tr.hide-for-xlarge-up,
  tr.hide-for-xlarge,
  tr.show-for-xlarge-down,
  tr.hide-for-xxlarge-only,
  tr.hide-for-xxlarge-up,
  tr.hide-for-xxlarge,
  tr.show-for-xxlarge-down {
    display: table-row;
  }

  th.hide-for-small-only,
  td.hide-for-small-only,
  th.show-for-small-up,
  td.show-for-small-up,
  th.hide-for-small,
  td.hide-for-small,
  th.hide-for-small-down,
  td.hide-for-small-down,
  th.hide-for-medium-only,
  td.hide-for-medium-only,
  th.show-for-medium-up,
  td.show-for-medium-up,
  th.hide-for-medium,
  td.hide-for-medium,
  th.hide-for-medium-down,
  td.hide-for-medium-down,
  th.show-for-large-only,
  td.show-for-large-only,
  th.show-for-large-up,
  td.show-for-large-up,
  th.show-for-large,
  td.show-for-large,
  th.show-for-large-down,
  td.show-for-large-down,
  th.hide-for-xlarge-only,
  td.hide-for-xlarge-only,
  th.hide-for-xlarge-up,
  td.hide-for-xlarge-up,
  th.hide-for-xlarge,
  td.hide-for-xlarge,
  th.show-for-xlarge-down,
  td.show-for-xlarge-down,
  th.hide-for-xxlarge-only,
  td.hide-for-xxlarge-only,
  th.hide-for-xxlarge-up,
  td.hide-for-xxlarge-up,
  th.hide-for-xxlarge,
  td.hide-for-xxlarge,
  th.show-for-xxlarge-down,
  td.show-for-xxlarge-down {
    display: table-cell !important;
  }
}

/* xlarge displays */
@media only screen and (width >= 90.0625em) {
  .hide-for-small-only,
  .show-for-small-up,
  .hide-for-small,
  .hide-for-small-down,
  .hide-for-medium-only,
  .show-for-medium-up,
  .hide-for-medium,
  .hide-for-medium-down,
  .hide-for-large-only,
  .show-for-large-up,
  .hide-for-large,
  .hide-for-large-down,
  .show-for-xlarge-only,
  .show-for-xlarge-up,
  .show-for-xlarge,
  .show-for-xlarge-down,
  .hide-for-xxlarge-only,
  .hide-for-xxlarge-up,
  .hide-for-xxlarge,
  .show-for-xxlarge-down {
    display: inherit !important;
  }

  .show-for-small-only,
  .hide-for-small-up,
  .show-for-small,
  .show-for-small-down,
  .show-for-medium-only,
  .hide-for-medium-up,
  .show-for-medium,
  .show-for-medium-down,
  .show-for-large-only,
  .hide-for-large-up,
  .show-for-large,
  .show-for-large-down,
  .hide-for-xlarge-only,
  .hide-for-xlarge-up,
  .hide-for-xlarge,
  .hide-for-xlarge-down,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up,
  .show-for-xxlarge,
  .hide-for-xxlarge-down {
    display: none !important;
  }

  .hidden-for-small-only,
  .visible-for-small-up,
  .hidden-for-small,
  .hidden-for-small-down,
  .hidden-for-medium-only,
  .visible-for-medium-up,
  .hidden-for-medium,
  .hidden-for-medium-down,
  .hidden-for-large-only,
  .visible-for-large-up,
  .hidden-for-large,
  .hidden-for-large-down,
  .visible-for-xlarge-only,
  .visible-for-xlarge-up,
  .visible-for-xlarge,
  .visible-for-xlarge-down,
  .hidden-for-xxlarge-only,
  .hidden-for-xxlarge-up,
  .hidden-for-xxlarge,
  .visible-for-xxlarge-down {
    position: static !important;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
  }

  .visible-for-small-only,
  .hidden-for-small-up,
  .visible-for-small,
  .visible-for-small-down,
  .visible-for-medium-only,
  .hidden-for-medium-up,
  .visible-for-medium,
  .visible-for-medium-down,
  .visible-for-large-only,
  .hidden-for-large-up,
  .visible-for-large,
  .visible-for-large-down,
  .hidden-for-xlarge-only,
  .hidden-for-xlarge-up,
  .hidden-for-xlarge,
  .hidden-for-xlarge-down,
  .visible-for-xxlarge-only,
  .visible-for-xxlarge-up,
  .visible-for-xxlarge,
  .hidden-for-xxlarge-down {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  table.hide-for-small-only,
  table.show-for-small-up,
  table.hide-for-small,
  table.hide-for-small-down,
  table.hide-for-medium-only,
  table.show-for-medium-up,
  table.hide-for-medium,
  table.hide-for-medium-down,
  table.hide-for-large-only,
  table.show-for-large-up,
  table.hide-for-large,
  table.hide-for-large-down,
  table.show-for-xlarge-only,
  table.show-for-xlarge-up,
  table.show-for-xlarge,
  table.show-for-xlarge-down,
  table.hide-for-xxlarge-only,
  table.hide-for-xxlarge-up,
  table.hide-for-xxlarge,
  table.show-for-xxlarge-down {
    display: table !important;
  }

  thead.hide-for-small-only,
  thead.show-for-small-up,
  thead.hide-for-small,
  thead.hide-for-small-down,
  thead.hide-for-medium-only,
  thead.show-for-medium-up,
  thead.hide-for-medium,
  thead.hide-for-medium-down,
  thead.hide-for-large-only,
  thead.show-for-large-up,
  thead.hide-for-large,
  thead.hide-for-large-down,
  thead.show-for-xlarge-only,
  thead.show-for-xlarge-up,
  thead.show-for-xlarge,
  thead.show-for-xlarge-down,
  thead.hide-for-xxlarge-only,
  thead.hide-for-xxlarge-up,
  thead.hide-for-xxlarge,
  thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }

  tbody.hide-for-small-only,
  tbody.show-for-small-up,
  tbody.hide-for-small,
  tbody.hide-for-small-down,
  tbody.hide-for-medium-only,
  tbody.show-for-medium-up,
  tbody.hide-for-medium,
  tbody.hide-for-medium-down,
  tbody.hide-for-large-only,
  tbody.show-for-large-up,
  tbody.hide-for-large,
  tbody.hide-for-large-down,
  tbody.show-for-xlarge-only,
  tbody.show-for-xlarge-up,
  tbody.show-for-xlarge,
  tbody.show-for-xlarge-down,
  tbody.hide-for-xxlarge-only,
  tbody.hide-for-xxlarge-up,
  tbody.hide-for-xxlarge,
  tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }

  tr.hide-for-small-only,
  tr.show-for-small-up,
  tr.hide-for-small,
  tr.hide-for-small-down,
  tr.hide-for-medium-only,
  tr.show-for-medium-up,
  tr.hide-for-medium,
  tr.hide-for-medium-down,
  tr.hide-for-large-only,
  tr.show-for-large-up,
  tr.hide-for-large,
  tr.hide-for-large-down,
  tr.show-for-xlarge-only,
  tr.show-for-xlarge-up,
  tr.show-for-xlarge,
  tr.show-for-xlarge-down,
  tr.hide-for-xxlarge-only,
  tr.hide-for-xxlarge-up,
  tr.hide-for-xxlarge,
  tr.show-for-xxlarge-down {
    display: table-row;
  }

  th.hide-for-small-only,
  td.hide-for-small-only,
  th.show-for-small-up,
  td.show-for-small-up,
  th.hide-for-small,
  td.hide-for-small,
  th.hide-for-small-down,
  td.hide-for-small-down,
  th.hide-for-medium-only,
  td.hide-for-medium-only,
  th.show-for-medium-up,
  td.show-for-medium-up,
  th.hide-for-medium,
  td.hide-for-medium,
  th.hide-for-medium-down,
  td.hide-for-medium-down,
  th.hide-for-large-only,
  td.hide-for-large-only,
  th.show-for-large-up,
  td.show-for-large-up,
  th.hide-for-large,
  td.hide-for-large,
  th.hide-for-large-down,
  td.hide-for-large-down,
  th.show-for-xlarge-only,
  td.show-for-xlarge-only,
  th.show-for-xlarge-up,
  td.show-for-xlarge-up,
  th.show-for-xlarge,
  td.show-for-xlarge,
  th.show-for-xlarge-down,
  td.show-for-xlarge-down,
  th.hide-for-xxlarge-only,
  td.hide-for-xxlarge-only,
  th.hide-for-xxlarge-up,
  td.hide-for-xxlarge-up,
  th.hide-for-xxlarge,
  td.hide-for-xxlarge,
  th.show-for-xxlarge-down,
  td.show-for-xxlarge-down {
    display: table-cell !important;
  }
}

/* xxlarge displays */
@media only screen and (width >= 120.0625em) {
  .hide-for-small-only,
  .show-for-small-up,
  .hide-for-small,
  .hide-for-small-down,
  .hide-for-medium-only,
  .show-for-medium-up,
  .hide-for-medium,
  .hide-for-medium-down,
  .hide-for-large-only,
  .show-for-large-up,
  .hide-for-large,
  .hide-for-large-down,
  .hide-for-xlarge-only,
  .show-for-xlarge-up,
  .hide-for-xlarge,
  .hide-for-xlarge-down,
  .show-for-xxlarge-only,
  .show-for-xxlarge-up,
  .show-for-xxlarge,
  .show-for-xxlarge-down {
    display: inherit !important;
  }

  .show-for-small-only,
  .hide-for-small-up,
  .show-for-small,
  .show-for-small-down,
  .show-for-medium-only,
  .hide-for-medium-up,
  .show-for-medium,
  .show-for-medium-down,
  .show-for-large-only,
  .hide-for-large-up,
  .show-for-large,
  .show-for-large-down,
  .show-for-xlarge-only,
  .hide-for-xlarge-up,
  .show-for-xlarge,
  .show-for-xlarge-down,
  .hide-for-xxlarge-only,
  .hide-for-xxlarge-up,
  .hide-for-xxlarge,
  .hide-for-xxlarge-down {
    display: none !important;
  }

  .hidden-for-small-only,
  .visible-for-small-up,
  .hidden-for-small,
  .hidden-for-small-down,
  .hidden-for-medium-only,
  .visible-for-medium-up,
  .hidden-for-medium,
  .hidden-for-medium-down,
  .hidden-for-large-only,
  .visible-for-large-up,
  .hidden-for-large,
  .hidden-for-large-down,
  .hidden-for-xlarge-only,
  .visible-for-xlarge-up,
  .hidden-for-xlarge,
  .hidden-for-xlarge-down,
  .visible-for-xxlarge-only,
  .visible-for-xxlarge-up,
  .visible-for-xxlarge,
  .visible-for-xxlarge-down {
    position: static !important;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
  }

  .visible-for-small-only,
  .hidden-for-small-up,
  .visible-for-small,
  .visible-for-small-down,
  .visible-for-medium-only,
  .hidden-for-medium-up,
  .visible-for-medium,
  .visible-for-medium-down,
  .visible-for-large-only,
  .hidden-for-large-up,
  .visible-for-large,
  .visible-for-large-down,
  .visible-for-xlarge-only,
  .hidden-for-xlarge-up,
  .visible-for-xlarge,
  .visible-for-xlarge-down,
  .hidden-for-xxlarge-only,
  .hidden-for-xxlarge-up,
  .hidden-for-xxlarge,
  .hidden-for-xxlarge-down {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  table.hide-for-small-only,
  table.show-for-small-up,
  table.hide-for-small,
  table.hide-for-small-down,
  table.hide-for-medium-only,
  table.show-for-medium-up,
  table.hide-for-medium,
  table.hide-for-medium-down,
  table.hide-for-large-only,
  table.show-for-large-up,
  table.hide-for-large,
  table.hide-for-large-down,
  table.hide-for-xlarge-only,
  table.show-for-xlarge-up,
  table.hide-for-xlarge,
  table.hide-for-xlarge-down,
  table.show-for-xxlarge-only,
  table.show-for-xxlarge-up,
  table.show-for-xxlarge,
  table.show-for-xxlarge-down {
    display: table !important;
  }

  thead.hide-for-small-only,
  thead.show-for-small-up,
  thead.hide-for-small,
  thead.hide-for-small-down,
  thead.hide-for-medium-only,
  thead.show-for-medium-up,
  thead.hide-for-medium,
  thead.hide-for-medium-down,
  thead.hide-for-large-only,
  thead.show-for-large-up,
  thead.hide-for-large,
  thead.hide-for-large-down,
  thead.hide-for-xlarge-only,
  thead.show-for-xlarge-up,
  thead.hide-for-xlarge,
  thead.hide-for-xlarge-down,
  thead.show-for-xxlarge-only,
  thead.show-for-xxlarge-up,
  thead.show-for-xxlarge,
  thead.show-for-xxlarge-down {
    display: table-header-group !important;
  }

  tbody.hide-for-small-only,
  tbody.show-for-small-up,
  tbody.hide-for-small,
  tbody.hide-for-small-down,
  tbody.hide-for-medium-only,
  tbody.show-for-medium-up,
  tbody.hide-for-medium,
  tbody.hide-for-medium-down,
  tbody.hide-for-large-only,
  tbody.show-for-large-up,
  tbody.hide-for-large,
  tbody.hide-for-large-down,
  tbody.hide-for-xlarge-only,
  tbody.show-for-xlarge-up,
  tbody.hide-for-xlarge,
  tbody.hide-for-xlarge-down,
  tbody.show-for-xxlarge-only,
  tbody.show-for-xxlarge-up,
  tbody.show-for-xxlarge,
  tbody.show-for-xxlarge-down {
    display: table-row-group !important;
  }

  tr.hide-for-small-only,
  tr.show-for-small-up,
  tr.hide-for-small,
  tr.hide-for-small-down,
  tr.hide-for-medium-only,
  tr.show-for-medium-up,
  tr.hide-for-medium,
  tr.hide-for-medium-down,
  tr.hide-for-large-only,
  tr.show-for-large-up,
  tr.hide-for-large,
  tr.hide-for-large-down,
  tr.hide-for-xlarge-only,
  tr.show-for-xlarge-up,
  tr.hide-for-xlarge,
  tr.hide-for-xlarge-down,
  tr.show-for-xxlarge-only,
  tr.show-for-xxlarge-up,
  tr.show-for-xxlarge,
  tr.show-for-xxlarge-down {
    display: table-row;
  }

  th.hide-for-small-only,
  td.hide-for-small-only,
  th.show-for-small-up,
  td.show-for-small-up,
  th.hide-for-small,
  td.hide-for-small,
  th.hide-for-small-down,
  td.hide-for-small-down,
  th.hide-for-medium-only,
  td.hide-for-medium-only,
  th.show-for-medium-up,
  td.show-for-medium-up,
  th.hide-for-medium,
  td.hide-for-medium,
  th.hide-for-medium-down,
  td.hide-for-medium-down,
  th.hide-for-large-only,
  td.hide-for-large-only,
  th.show-for-large-up,
  td.show-for-large-up,
  th.hide-for-large,
  td.hide-for-large,
  th.hide-for-large-down,
  td.hide-for-large-down,
  th.hide-for-xlarge-only,
  td.hide-for-xlarge-only,
  th.show-for-xlarge-up,
  td.show-for-xlarge-up,
  th.hide-for-xlarge,
  td.hide-for-xlarge,
  th.hide-for-xlarge-down,
  td.hide-for-xlarge-down,
  th.show-for-xxlarge-only,
  td.show-for-xxlarge-only,
  th.show-for-xxlarge-up,
  td.show-for-xxlarge-up,
  th.show-for-xxlarge,
  td.show-for-xxlarge,
  th.show-for-xxlarge-down,
  td.show-for-xxlarge-down {
    display: table-cell !important;
  }
}

/* Orientation targeting */
.show-for-landscape,
.hide-for-portrait {
  display: inherit !important;
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

/* Specific visibility for tables */
table.hide-for-landscape,
table.show-for-portrait {
  display: table !important;
}

thead.hide-for-landscape,
thead.show-for-portrait {
  display: table-header-group !important;
}

tbody.hide-for-landscape,
tbody.show-for-portrait {
  display: table-row-group !important;
}

tr.hide-for-landscape,
tr.show-for-portrait {
  display: table-row !important;
}

td.hide-for-landscape,
td.show-for-portrait,
th.hide-for-landscape,
th.show-for-portrait {
  display: table-cell !important;
}

@media only screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: inherit !important;
  }

  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }

  /* Specific visibility for tables */
  table.show-for-landscape,
  table.hide-for-portrait {
    display: table !important;
  }

  thead.show-for-landscape,
  thead.hide-for-portrait {
    display: table-header-group !important;
  }

  tbody.show-for-landscape,
  tbody.hide-for-portrait {
    display: table-row-group !important;
  }

  tr.show-for-landscape,
  tr.hide-for-portrait {
    display: table-row !important;
  }

  td.show-for-landscape,
  td.hide-for-portrait,
  th.show-for-landscape,
  th.hide-for-portrait {
    display: table-cell !important;
  }
}

@media only screen and (orientation: portrait) {
  .show-for-portrait,
  .hide-for-landscape {
    display: inherit !important;
  }

  .hide-for-portrait,
  .show-for-landscape {
    display: none !important;
  }

  /* Specific visibility for tables */
  table.show-for-portrait,
  table.hide-for-landscape {
    display: table !important;
  }

  thead.show-for-portrait,
  thead.hide-for-landscape {
    display: table-header-group !important;
  }

  tbody.show-for-portrait,
  tbody.hide-for-landscape {
    display: table-row-group !important;
  }

  tr.show-for-portrait,
  tr.hide-for-landscape {
    display: table-row !important;
  }

  td.show-for-portrait,
  td.hide-for-landscape,
  th.show-for-portrait,
  th.hide-for-landscape {
    display: table-cell !important;
  }
}

/* Touch-enabled device targeting */
.show-for-touch {
  display: none !important;
}

.hide-for-touch {
  display: inherit !important;
}

.touch .show-for-touch {
  display: inherit !important;
}

.touch .hide-for-touch {
  display: none !important;
}

/* Specific visibility for tables */
table.hide-for-touch {
  display: table !important;
}

.touch table.show-for-touch {
  display: table !important;
}

thead.hide-for-touch {
  display: table-header-group !important;
}

.touch thead.show-for-touch {
  display: table-header-group !important;
}

tbody.hide-for-touch {
  display: table-row-group !important;
}

.touch tbody.show-for-touch {
  display: table-row-group !important;
}

tr.hide-for-touch {
  display: table-row !important;
}

.touch tr.show-for-touch {
  display: table-row !important;
}

td.hide-for-touch {
  display: table-cell !important;
}

.touch td.show-for-touch {
  display: table-cell !important;
}

th.hide-for-touch {
  display: table-cell !important;
}

.touch th.show-for-touch {
  display: table-cell !important;
}

/* Screen reader-specific classes */
.show-for-sr {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.show-on-focus:focus,
.show-on-focus:active {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
}

/* Print visibility */
.print-only,
.show-for-print {
  display: none !important;
}

@media print {
  .print-only,
  .show-for-print {
    display: block !important;
  }

  .hide-on-print,
  .hide-for-print {
    display: none !important;
  }

  table.show-for-print {
    display: table !important;
  }

  thead.show-for-print {
    display: table-header-group !important;
  }

  tbody.show-for-print {
    display: table-row-group !important;
  }

  tr.show-for-print {
    display: table-row !important;
  }

  td.show-for-print {
    display: table-cell !important;
  }

  th.show-for-print {
    display: table-cell !important;
  }
}

/* stylelint-enable no-descending-specificity */
